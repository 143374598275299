import axios from "axios";
import * as actionTypes from "../action";
import { message } from "antd";
import { BASE_URL } from "../../components/config/web-config";

export const actionSendNotification =
  (userData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SEND_NOTIFICATION_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/notification`, userData);
      dispatch({ type: actionTypes.SEND_NOTIFICATION_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.SEND_NOTIFICATION,
          payload: result.data.data,
        });
        message.success("Send Notification Successfully", 5);
      } else if (result.data.status === 404) {
        dispatch({ type: actionTypes.SEND_NOTIFICATION, payload: [] });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.SEND_NOTIFICATION_LOADING, payload: false });
    }
  };