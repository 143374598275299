import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Upload,
  Image,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import {
  actionGetUserProfile,
  actionUpdateUserProfile,
} from "../../store/actions/authAction";
import {
  actionGetUserDetail,
  actionUpdateUser,
} from "../../store/actions/userAction";
import { useNavigate, useParams } from "react-router-dom";
import FileIcon from "../../assets/img/icon/file.png";
import { BASE_URL_UPLOAD } from "../config/web-config";
const { Search } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UpdateUser = (props) => {
  const {
    updateUserLoader,
    userDetailLoader,
    userDetailData,
    actionUpdateUserProfile,
    actionUpdateUser,
    actionGetUserDetail,
  } = props;

  const [loading, setLoading] = useState(false);
  const [userPicUrl, setUserPicUrl] = useState();
  const { userId } = useParams();

  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  useEffect(() => {
    actionGetUserDetail(userId);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      first_name: userDetailData.first_name,
      last_name: userDetailData.last_name,
      email: userDetailData.email,
      // country_phone_code: userDetailData.country_phone_code,
      country_phone_code: userDetailData.country_phone_code
        ? userDetailData.country_phone_code
        : "+91",

      mobile: userDetailData.mobile,
      password: userDetailData.password,
      roles: userDetailData.roles,
      // date_of_birth: userDetailData.date_of_birth
      //   ? dayjs(userDetailData.date_of_birth)
      //   : undefined,
      profile_pic: userDetailData.profile_pic,
      // gender: userDetailData.gender  ? "Male" : "Female",
      // gender: userDetailData.gender ? userDetailData.gender : userDetailData.gender = "O",
      gender: userDetailData.gender,
      hear_us_from: userDetailData.hear_us_from,
      // nationality: userDetailData.nationality,
      // country_of_residence: userDetailData.country_of_residence,
    });
    setUserPicUrl(userDetailData.profile_pic);
  }, [userDetailData]);

  useMemo(() => {}, [userDetailData]);

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setUserPicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      // profile_pic: userPicUrl
      //   ? userPicUrl.split("/").reverse()[0]
      //   : values.profile_pic.file.response.file.filename,
      profile_pic: userPicUrl ? userPicUrl.split("/").reverse()[0] : "",
      // date_of_birth: dayjs(values.date_of_birth).format("YYYY-MM-DD"),
      is_real_estate_egent: values.roles === "agent" ? true : false,
    };
    actionUpdateUser(request, Navigate, userId);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Fragment>
      <Spin spinning={userDetailLoader}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              className="form-card-border"
              title={<h3>Update Info</h3>}
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form
                    initialValues={{ country_phone_code: "+91" }}
                    autoComplete="off"
                    requiredMark={true}
                    form={form}
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          colon={false}
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter first name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter maximum 15 characters for first Name"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            // autoFocus="none"
                            type={"text"}
                            placeholder="Enter First Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="last_name"
                          label="Last Name"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter last name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter maximum 15 characters for last Name"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            // autoFocus="none"
                            type={"text"}
                            placeholder="Enter Last Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Email"
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="email"
                          rules={[
                            {
                              type: "email",
                              required: true,
                              // message: "Please enter email!",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            // autoFocus="none"
                            type={"text"}
                            placeholder="Enter Email"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          colon={false}
                          label="Mobile"
                          name="country_phone_code"
                          rules={[
                            {
                              required: true,
                              message: "Please select country code.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="+91"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "+91",
                                label: "IN +91",
                              },
                              {
                                value: "+1",
                                label: "US +1",
                              },
                              {
                                value: "+1",
                                label: "CN +1",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <p style={{ color: "transparent" }}>transparent</p>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          // label="Mobile"
                          colon={false}
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: "Please enter mobile!",
                            },
                            // ({ getFieldValue }) => ({
                            //   validator(_, value) {
                            //     if (value && value.length == 10) {
                            //       return Promise.resolve();
                            //     }
                            //     return Promise.reject(
                            //       new Error("Invalid Mobile Number!")
                            //     );
                            //   },
                            // }),
                          ]}
                        >
                          <Input
                            // autoFocus="none"
                            type={"text"}
                            placeholder="Enter Mobile Number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Roles"
                          colon={false}
                          name="roles"
                          rules={[
                            {
                              required: true,
                              message: "Please select Roles.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Role"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              {
                                value: "admin",
                                label: "Admin",
                              },
                              {
                                value: "user",
                                label: "User",
                              },
                              {
                                value: "bot",
                                label: "Bot",
                              },
                              {
                                value: "agent",
                                label: "Agent",
                              },
                              {
                                value: "bot_agent",
                                label: "Bot Agent",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Date Of Birth"
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: "Please enter date of birth!",
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col span={12}>
                        <Form.Item
                          label="Gender"
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please select gender.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Gender"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "M",
                                label: "Male",
                              },
                              {
                                value: "F",
                                label: "Female",
                              },
                              {
                                value: "O",
                                label: "Other",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Source"
                          colon={false}
                          name="hear_us_from"
                          rules={[
                            {
                              required: true,
                              message: "Please select Source.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Source"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              {
                                value: "Search Engine(Google,yahoo,etc.)",
                                label: "Search Engine(Google,yahoo,etc.)",
                              },
                              {
                                value: "Recommended by friend or colleague",
                                label: "Recommended by friend or colleague",
                              },
                              {
                                value: "Social media",
                                label: "Social media",
                              },
                              {
                                value: "Blog or publication",
                                label: "Blog or publication",
                              },
                              {
                                value: "Other",
                                label: "Other",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={24}>
                        <br />
                        <Form.Item
                          normalize={(value) => value.trim()}
                          name="profile_pic"
                          rules={[
                            {
                              required: userPicUrl ? false : true,
                              message: !userPicUrl && "Please select image!",
                            },
                          ]}
                        >
                          <Search
                            readOnly
                            placeholder="Image(200px x 200px)"
                            enterButton={
                              <>
                                <ImgCrop
                                  rotationSlider
                                  aspect={4 / 3}
                                  fillColor="white"
                                >
                                  <Upload
                                    name="file"
                                    maxCount={1}
                                    showUploadList={false}
                                    action={BASE_URL_UPLOAD}
                                    headers={{
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                          "scoutheimAdminToken"
                                        ),
                                    }}
                                    onChange={handleChangeUserPic}
                                  >
                                    <Image src={FileIcon} preview={false} />
                                  </Upload>
                                </ImgCrop>
                              </>
                            }
                          />
                          {userPicUrl && (
                            <Image
                              src={userPicUrl}
                              alt="file"
                              preview={false}
                              width={200}
                              style={{ marginTop: "10px" }}
                            />
                          )}
                        </Form.Item>
                      </Col> */}
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          name="profile_pic"
                          label="Profile Picture"
                          rules={[
                            {
                              required: userPicUrl ? false : true,
                              message: !userPicUrl && "Please select image!",
                            },
                          ]}
                        >
                          <div>
                            <>
                              <ImgCrop
                                rotationSlider
                                aspect={4 / 3}
                                fillColor="white"
                              >
                                {/* <Upload
                                    name="file"
                                    maxCount={1}
                                    showUploadList={false}
                                    action={BASE_URL_UPLOAD}
                                    headers={{
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem(
                                          "scoutheimAdminToken"
                                        ),
                                    }}
                                    onChange={handleChangeUserPic}
                                  >
                                    <Image src={FileIcon} preview={false} />
                                  </Upload> */}
                                <Upload
                                  name="file"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  action={BASE_URL_UPLOAD}
                                  headers={{
                                    Authorization:
                                      "Bearer " +
                                      localStorage.getItem(
                                        "scoutheimAdminToken"
                                      ),
                                  }}
                                  onChange={handleChangeUserPic}
                                >
                                  {userPicUrl ? (
                                    <img
                                      src={userPicUrl}
                                      alt="avatar"
                                      style={{
                                        width: "85%",
                                        height: "85%",
                                      }}
                                    />
                                  ) : (
                                    uploadButton
                                  )}
                                </Upload>
                              </ImgCrop>
                            </>
                          </div>
                          {/* {userPicUrl && (
                          <Image
                            src={userPicUrl}
                            alt="file"
                            preview={false}
                            width={200}
                            style={{ marginTop: "10px" }}
                          />
                        )} */}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item normalize={(value) => value.trim()}>
                          <Button
                            loading={updateUserLoader}
                            align="center"
                            htmlType="submit"
                            className="primary"
                            size="large"
                            style={{ width: 200, marginTop: "30px" }}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    updateUserLoader: state.user.updateUserLoader,
    userDetailLoader: state.user.userDetailLoader,
    userDetailData: state.user.userDetailData,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionUpdateUser,
})(UpdateUser);
