import axios from "axios";
import * as actionTypes from "../action";
import { message } from "antd";
import { BASE_URL } from "../../components/config/web-config";

export const actionGetAllBusiness =
  (skip, limit, search, sort,) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_ALL_BUSINESS_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/business?limit=${limit}&skip=${skip}${search ? "&search=" + search : ""
        }${sort ? "&sort=" + sort : ""}`);
      // let result = await axios.get(`${BASE_URL}/properties?limit=${limit}&skip=${skip}`);
      dispatch({ type: actionTypes.GET_ALL_BUSINESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_ALL_BUSINESS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_BUSINESS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_ALL_BUSINESS_DATA,
          payload: [],
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_ALL_BUSINESS_LOADING, payload: false });
    }
  };
export const actionAddBusiness =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_BUSINESS_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/business`, userData);
      dispatch({ type: actionTypes.ADD_BUSINESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_BUSINESS_DATA,
          payload: result.data.data,
        });

        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate("/business"), 1000);
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.ADD_BUSINESS_DATA,
          payload: [],
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_BUSINESS_LOADING, payload: false });
    }
  };

export const actionGetBusinessDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_BUSINESS_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/business/${id}`);
    dispatch({ type: actionTypes.GET_BUSINESS_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_BUSINESS_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_BUSINESS_DETAIL_LOADING, payload: false });
  }
};

export const actionDeleteBusiness = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DELETE_BUSINESS_LOADING, payload: false });
  try {
    let result = await axios.delete(`${BASE_URL}/business/${id}`);
    dispatch({ type: actionTypes.DELETE_BUSINESS_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_BUSINESS_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.DELETE_BUSINESS_LOADING, payload: false });
  }
};
export const actionUpdateBusiness =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_BUSINESS_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/business/${id}`, {
        ...userData,
      });
      dispatch({ type: actionTypes.UPDATE_BUSINESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success("Business Updated Successfully", 5);
        navigate && setTimeout(() => navigate("/business"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_BUSINESS_LOADING, payload: false });
    }
  };

export const actionActiveDeactiveBusiness =
  (is_active, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_ACTIVE_DEACTIVE_BUSINESS_LOADING, payload: { id, is_active: true } });
    try {
      let result = await axios.put(`${BASE_URL}/business/active/${id}`, {
        is_active,
      });

      dispatch({ type: actionTypes.UPDATE_ACTIVE_DEACTIVE_BUSINESS_LOADING, payload: { id, is_active: false } });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_ACTIVE_DEACTIVE_BUSINESS,
          payload: result.data.data,
        });

        message.success("Updated Successfully", 5);
      } else {
        // console.log("Error")
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      // console.log('errro');
      dispatch({ type: actionTypes.UPDATE_ACTIVE_DEACTIVE_BUSINESS_LOADING, payload: false });
    }
  };



// Business User Starts Here 


export const actionGetAllBusinessUsers = (skip, limit, search, getall) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_ALL_BUSINESS_USER_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/business-request?skip=${skip}&limit=${limit}${search ? "&search=" + search : ""}${getall ? "&getall=" + getall : ""}`);
    dispatch({ type: actionTypes.GET_ALL_BUSINESS_USER_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.GET_ALL_BUSINESS_USER_DATA, payload: result.data.data });
      dispatch({
        type: actionTypes.TOTAL_BUSINESS_USER_COUNT,
        payload: result.data.total_records,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_ALL_BUSINESS_USER_DATA,
        payload: [],
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_ALL_BUSINESS_USER_LOADING, payload: false });

  }
}
export const actionGetBusinessUserDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_BUSINESS_USER_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/business-request/${id}`);
    dispatch({ type: actionTypes.GET_BUSINESS_USER_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.GET_BUSINESS_USER_DETAIL_DATA, payload: result.data.data });
    } else message.error(result.data.message, 5);
  } catch (error) {
    console.log("Hello");
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_BUSINESS_USER_DETAIL_LOADING, payload: false });

  }
}

export const actionAddBusinessUser =
  (userData, navigate) => async (dispatch, getState) => {
    console.log({ userData });
    dispatch({ type: actionTypes.ADD_BUSINESS_USER_LOADER, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/business-request/${userData.business}`, userData);
      dispatch({ type: actionTypes.ADD_BUSINESS_USER_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate("/business-request"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_BUSINESS_USER_LOADER, payload: false });
    }
  };
export const actionUpdateBusinessUser =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_BUSINESS_USER_LOADER, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/business-request/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_BUSINESS_USER_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        navigate && navigate("/business-request");
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_BUSINESS_USER_LOADER, payload: false });
    }
  };
export const actionDeleteBusinessUser =
  (id, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_BUSINESS_USER_LOADER, payload: true });
    try {
      let result = await axios.delete(`${BASE_URL}/business-request/${id}`, id);
      dispatch({ type: actionTypes.DELETE_BUSINESS_USER_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({ type: actionTypes.DELETE_BUSINESS_USER_DATA, payload: id });
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate("/-request"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.DELETE_BUSINESS_USER_LOADER, payload: false });
    }
  };

// Approve Business User
export const actionApproveBusiness =
  (id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.APPROVE_BUSINESS_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/business-request/approve/${id}`);
      dispatch({ type: actionTypes.APPROVE_BUSINESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {

        dispatch({ type: actionTypes.APPROVE_BUSINESS_ID, payload: id });
        message.success(result.data.message, 5);
        //navigate && setTimeout(() => navigate("/users-agents"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.APPROVE_BUSINESS_LOADING, payload: false });
    }
  };

// Reject Business User
export const actionRejectBusiness =
  (id, note, form, setIsModalOpen) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.REJECT_BUSINESS_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/business-request/reject/${id}`, note);
      dispatch({ type: actionTypes.REJECT_BUSINESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({ type: actionTypes.REJECT_BUSINESS_ID, payload: result.data.data });
        message.success(result.data.message, 5);
        form && form.resetFields();
        setIsModalOpen && setIsModalOpen(false);
        //navigate && setTimeout(() => navigate("/users-agents"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.REJECT_BUSINESS_LOADING, payload: false });
    }
  };