import * as actionTypes from "../action";

const initialState ={
    favoritePropertyLoader:false,
    favoritePropertyData:[],
};

const favouritePropertyReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_FAVOURITE_PROPERTY_LOADING: {
        return { ...state, favoritePropertyLoader: action.payload };
      }
      case actionTypes.GET_FAVOURITE_PROPERTY_DATA: {
        return { ...state, favoritePropertyData: action.payload };
      }
  
      default:
        return state;
    }
  };
  
  export default favouritePropertyReducer;