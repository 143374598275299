import * as actionTypes from "../action";

const initialState = {
    dealsLoader: false,
    dealsData: [],
    addDealLoader: false,
    updateDealLoader: false,
    deleteDealLoader: false,
    dealDetailLoader: false,
    dealDetailData: "",
    dealsCount: "",
}


const dealsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_DEALS_LOADING: {
            return { ...state, dealsLoader: action.payload }
        }
        case actionTypes.GET_ALL_DEALS_DATA: {
            return { ...state, dealsData: action.payload }
        };
        case actionTypes.TOTAL_DEALS_COUNT: {
            return { ...state, dealsCount: action.payload }
        };
        case actionTypes.GET_DEALS_DETAIL_LOADING: {
            console.log(action.payload);
            return { ...state, dealDetailLoader: action.payload }
        };
        case actionTypes.GET_DEALS_DETAIL_DATA: {
            return { ...state, dealDetailData: action.payload }
        };
        case actionTypes.ADD_DEAL_LOADER: {
            return { ...state, addDealLoader: action.payload }
        };
        case actionTypes.UPDATE_DEAL_LOADER: {
            return { ...state, updateDealLoader: action.payload }
        };
        case actionTypes.DELETE_DEAL_LOADER: {
            return { ...state, deleteDealLoader: action.payload }
        };
        case actionTypes.DELETE_DEALS_DATA: {
            return { ...state, dealsData: [...state.dealsData.filter(deal => deal._id !== action.payload)] }
        };
        default:
            return state;
    }
}




export default dealsReducer;