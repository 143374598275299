import axios from "axios";
import * as actionTypes from "../action";
import { message } from "antd";
import { BASE_URL } from "../../components/config/web-config";

export const actionGetFavouriteProperty =
  (id ,limit,skip,getall) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_FAVOURITE_PROPERTY_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/favorites?limit=${limit}&skip=${skip}${getall ? "&getall=" + "Yes" : ""}&id=${id}`);
      // let result = await axios.get(`${BASE_URL}/properties?limit=${limit}&skip=${skip}`);
      dispatch({ type: actionTypes.GET_FAVOURITE_PROPERTY_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_FAVOURITE_PROPERTY_DATA,
          payload: result.data.data,
        });
        // dispatch({
        //   type: actionTypes.TOTAL_BUSINESS_COUNT,
        //   payload: result.data.total_records,
        // });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_FAVOURITE_PROPERTY_DATA,
          payload: [],
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_FAVOURITE_PROPERTY_LOADING, payload: false });
    }
  };