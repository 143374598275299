import axios from 'axios';
import * as actionTypes from '../action';
import { message } from 'antd';
import { BASE_URL } from '../../components/config/web-config';

export const actionGetAllDeals = (skip, limit, bussiness_id, code) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_ALL_DEALS_LOADING, payload: true });
    try {
        let result = await axios.get(`${BASE_URL}/dod?skip=${skip}&limit=${limit}${bussiness_id ? "&bussiness_id=" + bussiness_id : ""}${code ? "&keyword=" + code : ""}${"&own_dod=Yes"}`);
        dispatch({ type: actionTypes.GET_ALL_DEALS_LOADING, payload: false });
        if (parseInt(result.data.status) === 200) {
            dispatch({ type: actionTypes.GET_ALL_DEALS_DATA, payload: result.data.data });
            dispatch({
                type: actionTypes.TOTAL_DEALS_COUNT,
                payload: result.data.total_records,
            });
        } else if (parseInt(result.data.status) === 404) {
            dispatch({
                type: actionTypes.GET_ALL_DEALS_DATA,
                payload: [],
            });
            dispatch({
                type: actionTypes.TOTAL_DEALS_COUNT,
                payload: 0,
            });
        } else message.error(result.data.message, 5);
    } catch (error) {
        message.error(error.message, 5);
        dispatch({ type: actionTypes.GET_ALL_DEALS_LOADING, payload: false });

    }
}
export const actionGetDealDetail = (id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_DEALS_DETAIL_LOADING, payload: true });
    try {
        let result = await axios.get(`${BASE_URL}/dod/${id}`);
        dispatch({ type: actionTypes.GET_DEALS_DETAIL_LOADING, payload: false });
        if (parseInt(result.data.status) === 200) {
            dispatch({ type: actionTypes.GET_DEALS_DETAIL_DATA, payload: result.data.data });
        } else message.error(result.data.message, 5);
    } catch (error) {
        console.log("Hello");
        message.error(error.message, 5);
        dispatch({ type: actionTypes.GET_DEALS_DETAIL_LOADING, payload: false });

    }
}

export const actionAddDeal =
    (userData, navigate) => async (dispatch, getState) => {
        console.log({ userData });
        dispatch({ type: actionTypes.ADD_DEAL_LOADER, payload: true });
        try {
            let result = await axios.post(`${BASE_URL}/dod/${userData.business}`, userData);
            dispatch({ type: actionTypes.ADD_DEAL_LOADER, payload: false });
            if (parseInt(result.data.status) === 200) {
                message.success("Deals Added Successfully", 5);
                navigate && setTimeout(() => navigate("/deals-of-the-day"), 1000);
            } else message.error(result.data.message, 5);
        } catch (error) {
            message.error(error.message, 5);
            dispatch({ type: actionTypes.ADD_DEAL_LOADER, payload: false });
        }
    };
export const actionUpdateDeal =
    (userData, navigate, id) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.UPDATE_DEAL_LOADER, payload: true });
        try {
            let result = await axios.put(`${BASE_URL}/dod/${id}`, userData);
            dispatch({ type: actionTypes.UPDATE_DEAL_LOADER, payload: false });
            if (parseInt(result.data.status) === 200) {
                navigate && navigate("/deals-of-the-day");
                message.success("Deals Updated Successfully", 5);
            } else message.error(result.data.message, 5);
        } catch (error) {
            message.error(error.message, 5);
            dispatch({ type: actionTypes.UPDATE_DEAL_LOADER, payload: false });
        }
    };
export const actionDeleteDeal =
    (id, navigate) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.DELETE_DEAL_LOADER, payload: true });
        try {
            let result = await axios.delete(`${BASE_URL}/dod/${id}`, id);
            dispatch({ type: actionTypes.DELETE_DEAL_LOADER, payload: false });
            if (parseInt(result.data.status) === 200) {
                dispatch({ type: actionTypes.DELETE_DEALS_DATA, payload: id });
                message.success(result.data.message, 5);
                navigate && setTimeout(() => navigate("/deals-of-the-day"), 1000);
            } else message.error(result.data.message, 5);
        } catch (error) {
            message.error(error.message, 5);
            dispatch({ type: actionTypes.DELETE_DEAL_LOADER, payload: false });
        }
    };