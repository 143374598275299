import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import image1 from "../../assets/img/dashboard/view-property.png";
import {
  Button,
  Card,
  Col,
  Image,
  Descriptions,
  List,
  Popconfirm,
  Row,
  Spin,
  Typography,
  Avatar,
} from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams, Link } from "react-router-dom";
import logo from "../../assets/img/main-wrapper/user.jpg";
import verifiedIcon from "../../assets/img/icon/verify.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  actionGetUserDetail,
  actionDeleteUser,
} from "../../store/actions/userAction";
import { actionGetFavouriteProperty } from "../../store/actions/favouritePropertyAction";
import user from "../../assets/img/main-wrapper/user.jpg";
import { removeUnderScore } from "../../common/function";

const { Title, Text } = Typography;

const UserDetail = (props) => {
  const Navigate = useNavigate();
  const {
    actionGetUserDetail,
    userDetailData,
    userDetailLoader,
    actionDeleteUser,
    deleteUserLoader,
    actionGetFavouriteProperty,
    favoritePropertyData,
  } = props;
  const { userId } = useParams();
  // console.log(userId);
  useEffect(() => {
    actionGetUserDetail(userId);
    actionGetFavouriteProperty(userId, 20, 0);
  }, []);

  const confirm = (id) => {
    actionDeleteUser(id);
  };

  let icon =
    userDetailData.roles === "user" ||
    userDetailData.roles === "admin" ||
    userDetailData.roles === "agent" ? (
      <img
        src={verifiedIcon}
        className=""
        width={18}
        style={{ marginLeft: "8px" }}
      />
    ) : (
      ""
    );

  const data = [
    // { label: "Name", value: userDetailData.first_name + " " + last_name },
    {
      label: "Name",
      value: userDetailData.first_name + " " + userDetailData.last_name,
    },
    { label: "Email", value: userDetailData.email },
    {
      label: "Gender",
      value:
        userDetailData.gender === "M"
          ? "Male"
          : userDetailData.gender === "F"
          ? "Female"
          : userDetailData.gender === "O"
          ? "Other"
          : "-",
    },
    { label: "Mobile", value: userDetailData.mobile },
    // { label: "Date of Birth", value: userDetailData.dob },
    {
      label: "Roles",
      value: (
        <span style={{ display: "flex", alignItems: "center" }}>
          {userDetailData.roles && removeUnderScore(userDetailData.roles)}
          {icon}
        </span>
      ),
    },
    {
      label: "Source",
      value: userDetailData.hear_us_from ? userDetailData.hear_us_from : "-",
    },
  ];

  return (
    <>
      <Spin spinning={userDetailLoader}>
        <div className="view-property">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li"
                    align="middle"
                    title="View Profile "
                    bordered={false}
                    style={{ width: "100%" }}
                  >
                    <Image
                      preview={{ mask: "" }}
                      src={userDetailData.profile_pic}
                      style={{
                        border: "3px solid #FF9A3E",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      width={100}
                      height={100}
                    />
                    <p className="user-name">{`${
                      userDetailData.first_name + " " + userDetailData.last_name
                    } (${userDetailData.roles})`}</p>
                    <p className="user-email">{userDetailData.email}</p>
                  </Card>
                </Col>
                {/* {favoritePropertyData && favoritePropertyData.properties && favoritePropertyData.properties.length > 0 ? */}
                <Col span={24}>
                  <Card
                    className="form-card-border list-layout-li favourite-property-card"
                    align="middle"
                    title="Favourite Properties"
                    bordered={false}
                    style={{ width: "100%", padding: "0px" }}
                  >
                    <List
                      style={{
                        maxHeight: 400,
                        height: "auto",
                        overflow: "auto",
                      }}
                      className="scrollable-list"
                      itemLayout="horizontal"
                      dataSource={favoritePropertyData.properties}
                      renderItem={(item, index) => (
                        <List.Item>
                          <div>
                            <Avatar
                              shape="square"
                              size={64}
                              src={item.property_images[0]}
                            />
                          </div>
                          <div style={{ marginLeft: "15px" }}>
                            <a
                              href={`/view-property/${item._id}`}
                            >{`${item.street_address}, ${item.city}, ${item.state}, ${item.country}`}</a>
                            <br />
                            <span>
                              Date Added :{" "}
                              <span style={{ color: "#443527" }}>
                                {dayjs(item.createdAt).format("DD/MM/YYYY")}
                              </span>
                            </span>
                            <br />
                            <span>${item.asking_price}</span>
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={16}>
              <Card
                className="body-padding form-card-border list-layout-li"
                title="More Details"
                bordered={false}
                style={{ width: "100%" }}
              >
                <Descriptions
                  title=""
                  layout="horizontal"
                  colon={true}
                  bordered
                  column={1}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <Descriptions.Item
                        key={index}
                        label={item.label}
                        style={{
                          background:
                            index % 2 !== 0 ? "rgba(239, 239, 239, 1)" : "",
                        }} // Set color dynamically or default to black
                      >
                        {item.value ? item.value : "-"}
                      </Descriptions.Item>
                    ))}
                </Descriptions>
                <br />
                <br />
                <Link to={`/update-user/${userDetailData._id}`}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                  >
                    Update
                  </Button>
                </Link>

                <Popconfirm
                  okButtonProps={{
                    loading: deleteUserLoader,
                  }}
                  placement="topLeft"
                  title="Are you sure to delete this User?"
                  onConfirm={() => confirm(userDetailData._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                    icon={<DeleteOutlined />}
                    type="danger"
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetailData: state.user.userDetailData,
    userDetailLoader: state.user.userDetailLoader,
    deleteUserLoader: state.user.deleteUserLoader,
    favoritePropertyData: state.favouriteProperty.favoritePropertyData,
    favoritePropertyLoader: state.favouriteProperty.favoritePropertyLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionDeleteUser,
  actionGetFavouriteProperty,
})(UserDetail);
