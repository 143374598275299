import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import image1 from "../../assets/img/dashboard/view-property.png";
import {
  Button,
  Card,
  Col,
  Image,
  Descriptions,
  List,
  Popconfirm,
  Row,
  Spin,
  Typography,
  Avatar
} from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams, Link } from "react-router-dom";
import logo from "../../assets/img/main-wrapper/user.jpg";
import verifiedIcon from "../../assets/img/icon/verify.png";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { actionGetBusinessUserDetail, actionDeleteBusinessUser } from "../../store/actions/businessAction";

import user from "../../assets/img/main-wrapper/user.jpg";
import { removeUnderScore } from "../../common/function";

const { Title, Text } = Typography;

const BusinessRequestDetail = (props) => {
  const Navigate = useNavigate();
  const { actionGetBusinessUserDetail, businessUserDetailData, businessUserDetailLoader, actionDeleteBusinessUser, deleteBusinessUserLoader, } = props;
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      actionGetBusinessUserDetail(id);
    }
  }, [id]);


  const confirm = (id) => {
    actionDeleteBusinessUser(id, Navigate);
  };

  const data = [
    {
      label: "Name",
      value: businessUserDetailData.first_name + " " + businessUserDetailData.last_name,
    },
    { label: "Email", value: businessUserDetailData.email },
    { label: "Mobile", value: businessUserDetailData.mobile },
    { label: "Source", value: businessUserDetailData.source ? businessUserDetailData.source : "-" },
    { label: "Business Name", value: businessUserDetailData.business_name || "-" },
    { label: "Business Website", value: businessUserDetailData.business_website || "-" },
    { label: "Business Address", value: businessUserDetailData.business_address || "-" },
    { label: "Reason For Rejecting Business", value: businessUserDetailData.note || "-" }
  ];



  return (
    <>
      <Spin spinning={businessUserDetailLoader}>
        <div className="view-property">
          <Row gutter={[16, 16]}>


            <Col span={16}>
              <Card
                className="body-padding form-card-border list-layout-li"
                title="Business User Detail"
                bordered={false}
                style={{ width: "100%" }}
              >
                <Descriptions
                  title=""
                  layout="horizontal"
                  colon={true}
                  bordered
                  column={1}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <Descriptions.Item
                        key={index}
                        label={item.label}
                        style={{
                          background:
                            index % 2 !== 0 ? "rgba(239, 239, 239, 1)" : "",
                        }} // Set color dynamically or default to black
                      >
                        {item.value ? item.value : "-"}
                      </Descriptions.Item>
                    ))}
                </Descriptions>
                <br />
                <br />
                <Link to={`/update-business-request/${businessUserDetailData._id}`}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default"
                  >
                    Update
                  </Button>
                </Link>

                <Popconfirm
                  okButtonProps={{
                    loading: deleteBusinessUserLoader,
                  }}
                  placement="topLeft"
                  title="Are you sure to Delete this Business User?"
                  onConfirm={() => confirm(businessUserDetailData._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginBottom: 20, marginLeft: 30, width: 150 }}
                    size="large"
                    shape="default" loading={deleteBusinessUserLoader}
                    icon={<DeleteOutlined />}
                    type="danger"
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    businessUserDetailData: state.business.businessUserDetailData,
    businessUserDetailLoader: state.business.businessUserDetailLoader,
    deleteBusinessUserLoader: state.business.deleteBusinessUserLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetBusinessUserDetail,
  actionDeleteBusinessUser,
})(BusinessRequestDetail);
