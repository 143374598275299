import {
  ApartmentOutlined,
  BellOutlined,
  DashboardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PictureOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  BusinessOutlined,
  TeamOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

import {
  Card,
  Col,
  Image,
  Layout,
  Menu,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import deals from "../../assets/img/dashboard/deals.svg";
import dealsWhite from "../../assets/img/icon/dealsWhite.svg";
import property from "../../assets/img/dashboard/property.svg";
import propertyWhite from "../../assets/img/icon/propertyWhite.svg";
import editIcon from "../../assets/img/main-wrapper/edit.png";
import logo from "../../assets/img/main-wrapper/logo.png";
import logout from "../../assets/img/main-wrapper/logout.png";
import notification from "../../assets/img/main-wrapper/notification.png";
import { logoutUser } from "../../store/actions/authAction";
//import Card from "antd/es/card/Card";
import { connect } from "react-redux";
const { Header, Content, Footer, Sider } = Layout;

const MainLayout = (props) => {
  const { userProfileData, logoutUser } = props;
  const navigate = useNavigate();
  const history = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [keyName, setKeyName] = useState("1");

  useMemo(() => {
    if (history.pathname === "/") {
      setKeyName("1");
    } else if (
      history.pathname === "/users-agents" ||
      history.pathname === "/create-new" ||
      history.pathname.startsWith("/update-user/") ||
      history.pathname.startsWith("/view-user/")
    ) {
      setKeyName("2");
    } else if (
      history.pathname === "/deals-of-the-day" ||
      history.pathname === "/create-deal" ||
      history.pathname.startsWith("/update-deal/") ||
      history.pathname.startsWith("/view-deal/")
    ) {
      setKeyName("3");
    } else if (
      history.pathname === "/properties" ||
      history.pathname === "/create-property" ||
      history.pathname.startsWith("/update-property/") ||
      history.pathname.startsWith("/view-property/")
    ) {
      setKeyName("4");
    } else if (history.pathname === "/subscription") {
      setKeyName("5");
    } else if (history.pathname === "/activities") {
      setKeyName("6");
    } else if (
      history.pathname === "/business" ||
      history.pathname === "/create-business" ||
      history.pathname.startsWith("/update-business/") ||
      history.pathname.startsWith("/view-business/")
    ) {
      setKeyName("7");
    } else if (
      history.pathname === "/business-request" ||
      history.pathname === "/create-business-user" ||
      history.pathname.startsWith("/update-business-request/") ||
      history.pathname.startsWith("/business-request/")
    ) {
      setKeyName("8");
    } else setKeyName("1");
  }, [history]);

  const itemData =
    userProfileData && userProfileData.roles === "admin"
      ? [
          {
            key: "1",
            icon: <DashboardOutlined style={{ fontSize: "20px" }} />,
            label: <NavLink to="/">Dashboard</NavLink>,
            title: "",
          },
          {
            key: "2",
            icon: <UserOutlined style={{ fontSize: "20px" }} />,
            label: (
              <NavLink
                to="/users-agents"
                style={{ fontWeight: keyName === "2" && "bold" }}
              >
                Users/Agents
              </NavLink>
            ),
            title: "",
          },
          {
            key: "3",
            icon: (
              <img
                src={
                  history.pathname === "/deals-of-the-day" ||
                  history.pathname === "/create-deal" ||
                  history.pathname.startsWith("/view-deal/") ||
                  history.pathname.startsWith("/update-deal/")
                    ? dealsWhite
                    : deals
                }
                style={{ width: "20px", filter: "contrast(200%)" }}
              />
            ),
            label: (
              <NavLink
                to="/deals-of-the-day"
                style={{ fontWeight: keyName === "3" && "bold" }}
              >
                Deals of the day
              </NavLink>
            ),
            title: "",
          },
          {
            key: "4",
            icon: (
              <img
                src={
                  history.pathname === "/properties" ||
                  history.pathname === "/create-property" ||
                  history.pathname.startsWith("/view-property/") ||
                  history.pathname.startsWith("/update-property/")
                    ? propertyWhite
                    : property
                }
                style={{ width: "20px", filter: "contrast(200%)" }}
              />
            ),
            label: (
              <NavLink
                to="/properties"
                style={{ fontWeight: keyName === "4" && "bold" }}
              >
                Properties
              </NavLink>
            ),
            title: "",
          },
          /* {
          key: "5",
          icon: <BellOutlined style={{ fontSize: "20px" }} />,
          label: <NavLink to="/subscription" style={{ fontWeight: keyName === '5' && 'bold' }}>Subscription</NavLink>,
          title: "",
        }, */
          /*    {
             key: "6",
             icon: <PictureOutlined style={{ fontSize: "20px" }} />,
             label: <NavLink to="/activities" style={{ fontWeight: keyName === '6' && 'bold' }}>Activities</NavLink>,
             title: "",
           }, */
          {
            key: "7",
            icon: (
              <ApartmentOutlined
                style={{ fontSize: "20px", fontWeight: 400 }}
              />
            ),
            label: (
              <NavLink
                to="/business"
                style={{ fontWeight: keyName === "7" && "bold" }}
              >
                Business
              </NavLink>
            ),
            title: "",
          },
          {
            key: "8",
            icon: <SolutionOutlined style={{ fontSize: "20px" }} />,
            label: (
              <NavLink
                to="/business-request"
                style={{ fontWeight: keyName === "8" && "bold" }}
              >
                Business Request
              </NavLink>
            ),
            title: "",
          },
        ]
      : [
          {
            key: "1",
            icon: <DashboardOutlined style={{ fontSize: "20px" }} />,
            label: (
              <NavLink to="/" style={{ fontWeight: keyName === "1" && "bold" }}>
                {" "}
                Dashboard
              </NavLink>
            ),
            title: "",
          },
          {
            key: "3",
            icon: (
              <img
                src={
                  history.pathname === "/deals-of-the-day" ||
                  history.pathname === "/create-deal" ||
                  history.pathname.startsWith("/view-deal/") ||
                  history.pathname.startsWith("/update-deal/")
                    ? dealsWhite
                    : deals
                }
                style={{ width: "20px", filter: "contrast(200%)" }}
              />
            ),
            label: (
              <NavLink
                to="/deals-of-the-day"
                style={{ fontWeight: keyName === "3" && "bold" }}
              >
                Deals of the day
              </NavLink>
            ),
            title: "",
          },
        ];

  return (
    <Layout id="main-layout">
      <Sider
        width={250}
        collapsedWidth={"0"}
        style={{
          display: `${
            history.pathname === "/login" ||
            history.pathname === "/forgot-password" ||
            history.pathname === "/reset-password" ||
            history.pathname === "/reset-password/" ||
            history.pathname === "/register"
              ? "none"
              : ""
          }`,
        }}
        className="sidebar"
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          //console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
      >
        <div className="logo">
          <Image preview={false} src={logo} />
        </div>
        <Card className="card-info">
          <Image
            className="profile-img"
            preview={false}
            src={userProfileData.profile_pic}
            width={50}
            height={50}
            style={{ objectFit: "cover" }}
          />
          <div>
            <p>
              {userProfileData.first_name + " " + userProfileData.last_name}
            </p>
            <p>
              {userProfileData.email && userProfileData.email.length > 15
                ? userProfileData.email.substr(0, 15) + "...."
                : userProfileData.email}
            </p>
          </div>
          <Link to="/update-profile">
            <Image className="edit-img" preview={false} src={editIcon} />
          </Link>
        </Card>
        <Col span={24} align="middle">
          <NavLink to={`/change-password/${userProfileData._id}`}>
            {
              /*  collapsed ? <LockOutlined /> : */ <Typography.Title
                level={5}
                style={{
                  margin: "0px 0",
                  color: "black",
                }}
              >
                Change Password
              </Typography.Title>
            }
          </NavLink>
        </Col>
        <Menu
          id="overflow-menu"
          theme="light"
          mode="inline"
          selectedKeys={[keyName]} // Use selectedKeys instead of defaultSelectedKeys
          items={itemData}
        />
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: 0,
            display: `${
              history.pathname === "/login" ||
              history.pathname === "/forgot-password" ||
              history.pathname === "/reset-password" ||
              history.pathname === "/reset-password/" ||
              history.pathname === "/register"
                ? "none"
                : ""
            }`,
          }}
        >
          {history.pathname === "/login" ||
          history.pathname === "/forgot-password" ||
          history.pathname === "/reset-password/" ||
          history.pathname === "/reset-password" ||
          history.pathname === "/register" ? (
            ""
          ) : (
            <Row>
              <Col span={3} align="left">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </Col>
              <Col span={20} align="right">
                <Popconfirm
                  className="logout-popconfirm"
                  description={
                    <Typography.Text>
                      Are you sure you want to logout?
                    </Typography.Text>
                  }
                  onConfirm={() => logoutUser(navigate)}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Image
                    preview={false}
                    src={logout}
                    style={{ paddingLeft: 10, cursor: "pointer" }}
                  />
                </Popconfirm>
              </Col>
            </Row>
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px 0",
            height: `${
              history.pathname === "/login" ||
              history.pathname === "/forgot-password" ||
              history.pathname === "/reset-password" ||
              history.pathname === "/reset-password/" ||
              history.pathname === "/register"
                ? "100vh"
                : " calc(100vh - 80px)"
            }`,
            overflowY: "auto",
          }}
        >
          <div
            id={
              history.pathname === "/login" ||
              history.pathname === "/forgot-password" ||
              history.pathname === "/reset-password" ||
              history.pathname === "/reset-password/" ||
              history.pathname === "/register"
                ? "content-full"
                : ""
            }
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
  };
};

export default connect(mapStateToProps, { logoutUser })(MainLayout);
