import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  ApartmentOutlined
} from "@ant-design/icons";
import {
  Button,
  Image,
  Col,
  Dropdown,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Switch
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  actionDeleteBusiness,
  actionGetAllBusiness,
  actionActiveDeactiveBusiness,
} from "../../store/actions/businessAction";
import DealIcon from "../../assets/img/dashboard/deals.png";

const Business = (props) => {
  const {
    businessLoader,
    actionGetAllBusiness,
    businessCount,
    actionDeleteBusiness,
    businessData,
    deleteBusinessLoader,
    actionActiveDeactiveBusiness,
    updateActiveDeactiveBusinessLoader
  } = props;

  const [search, setSearch] = useState();
  const [sort, setSort] = useState();
  const [activeDeactiveLoading, setactiveDeactiveLoading] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    actionGetAllBusiness(0, 10);
  }, []);

  const confirm = (id) => {
    actionDeleteBusiness(id);
  };
  const handleActiveDeactiveBusiness = (e, id) => {
    actionActiveDeactiveBusiness(e, id);
  };

  useMemo(() => { }, [businessData]);

  const columns = [
    //key = 'business_name',
    {
      title: "Business Logo",
      dataIndex: "business_logo",
      key: "business_logo",
      render: (text, record) => {
        return (
          <>
            {/* <Image src={text} width={100} alt="" />
             */}
            <Image
              style={{ width: "70px", objectFit: "cover", aspectRatio: 3 / 3 }}
              src={text}
              width={50}
              preview={{ mask: "" }}
              className="property-img-border"
            />
          </>
        )
      }
    },
    { title: "Name", dataIndex: "business_name", key: "business_name" },
    { title: "Email", dataIndex: "business_email", key: "business_email" },
    { title: "Owner's Name", dataIndex: "business_owner", key: "business_owner" },

    {
      title: "Mobile no",
      dataIndex: "business_mobile",
      key: "business_mobile",
    },
    {
      title: "Office No",
      dataIndex: "business_office_phone",
      key: "business_office_phone",
    },
    {
      title: "Website",
      dataIndex: "business_website",
      key: "business_website",
      render: (text, record) => (
        <a style={{ color: 'black' }} href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Active/Deactive",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => {
        return (
          <>
            {/* <Switch checked={record.is_active} loading={record.is_loading} onClick={(e) => {
              console.log(e);
             // handleActiveDeactiveBusiness(e, record._id);
            }} />
 */}
            <Popconfirm
              title="Are you sure you want to change the status?"
              onConfirm={() => {
                handleActiveDeactiveBusiness(!record.is_active, record._id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Switch
                checked={record.is_active}
                loading={record.is_loading}
              />
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: "Action",
      // <>
      //   <Image
      //     src={filter}
      //     preview={false}
      //     style={{ cursor: "pointer", width: 24 }}
      //     onClick={() => setModal1Open(true)}
      //   />
      // </>
      fixed: "right",
      align: "center",
      width: "80px",
      key: "action",
      render: (_, item) => (
        <>
          <Dropdown
            placement="bottom"
            menu={{
              items: [
                {
                  label: (
                    <span>
                      <Link
                        to={`/view-business/${item._id}`}
                        style={{ color: "black" }}
                      >
                        <EyeOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        View
                      </Link>
                    </span>
                  ),
                },
                {
                  label: (
                    <span>
                      <Link
                        to={`/update-business/${item._id}`}
                        style={{ color: "black" }}
                      >
                        <EditOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Edit
                      </Link>
                    </span>
                  ),
                },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteBusinessLoader,
                      }}
                      placement="topLeft"
                      title="Are you sure to delete this Business?"
                      onConfirm={() => confirm(item._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span style={{ color: "black" }}>
                        <DeleteOutlined
                          style={{
                            color: "#FF9A3E",
                            fontSize: "18px",
                            marginRight: 10,
                          }}
                        />
                        Delete
                      </span>
                    </Popconfirm>
                  ),
                },
              ],
            }}
          >
            <a>
              <EllipsisOutlined style={{ fontSize: "20px" }} />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  const tableData = [];
  businessData &&
    businessData.length > 0 &&
    businessData.map((data) => {
      tableData.push({
        key: data._id,
        _id: data._id,
        business_name: data.business_name,
        business_email: data.business_email,
        business_logo: data.business_logo,
        business_mobile: data.business_mobile,
        business_office_phone: data.business_office_phone || "-",
        business_website: data.business_website || "-",
        broker_name: data.broker_name || "-",
        business_address: data.business_address,
        is_active: data.is_active,
        is_loading: data.is_loading,
        business_owner: data.user.first_name + ' ' + data.user.last_name
      });
    });



  const handleSearch = (e) => {
    // console.log(e);
  };

  const handleSortChange = (e) => {
    // console.log(e);
  };

  const onFinishSearch = (value) => {
    setSearch(value.search ? value.search : "");
    setSort(value.sort ? value.sort : "");
    actionGetAllBusiness(
      0,
      10,
      value.search ? value.search : "",
      value.sort ? value.sort : ""
    );
  };

  const onClickPagination = (e) => {
    actionGetAllBusiness(
      e.current - 1,
      e.pageSize,
      search ? search : ""
    );
  };

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row className="main-card-wrapper" gutter={16}>
          <Col span={24}>
            <div className="card-blue">
              <Row>
                <Col span={20} className="text-card">
                  <p className="font-title">Business</p>
                  <p className="font-text">{businessCount ? businessCount : 0}</p>
                </Col>
                <Col span={4} className="image-card-blue">
                  {/* <Image src={DealIcon} preview={false} /> */}
                  <ApartmentOutlined style={{ fontSize: '35px', color: 'white' }} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          className="main-card-wrapper"
          gutter={16}
          justify="space-around"
          align="middle"
        >
          <Col span={4} style={{ fontWeight: "bold	", fontSize: "15px" }}>
            Business
          </Col>
          <Col span={16} align="middle">
            <Form
              name="basic"
              layout="inline"
              onFinish={onFinishSearch}
              autoComplete="off"
              style={{ justifyContent: "center" }}
            >
              <Form.Item name="search">
                <Input
                  onChange={handleSearch}
                  placeholder="Search Here"
                  allowClear
                />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit">Search</Button>
              </Form.Item>
            </Form>
          </Col>

          <Col span={4} align="right">
            <Link to="/create-business">
              <Button>Create Business</Button>
            </Link>
          </Col>

          <Col span={24} style={{ marginTop: "10px" }}>
            <Table
              columns={columns}
              dataSource={tableData}
              loading={businessLoader}
              sticky={true}
              onChange={(e) => onClickPagination(e)}
              size="small"
              pagination={{
                total: businessCount,
                hideOnSinglePage: true,
              }}
              scroll={{
                x: 1500,
                y: window.innerWidth < 1500 ? "40vh" : "50vh",

              }}
            />
          </Col>
        </Row>
      </Space>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessLoader: state.business.businessLoader,
    businessData: state.business.businessData,
    deleteBusinessLoader: state.business.deleteBusinessLoader,
    businessCount: state.business.businessCount,
    updateActiveDeactiveBusinessLoader: state.business.updateActiveDeactiveBusinessLoader,

  };
};

export default connect(mapStateToProps, {
  actionGetAllBusiness,
  actionDeleteBusiness,
  actionActiveDeactiveBusiness
})(Business);
