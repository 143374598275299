import { combineReducers } from "redux";
import authReducer from "./authReducer";
import propertyReducer from "./propertyReducer";
import userReducer from "./userReducer";
import dealsReducer from "./dealsReducer";
import businessReducer from "./businessReducer";
import favouritePropertyReducer from "./favouritePropertyReducer";
import notificationReducer from "./notificationReducer";


const rootReducer = combineReducers({
  auth: authReducer,
  properties: propertyReducer,
  user: userReducer,
  dealz: dealsReducer,
  business: businessReducer,
  favouriteProperty: favouritePropertyReducer, 
  notification: notificationReducer,
});

export default rootReducer;
