import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Col,
  theme,
  Form,
  Upload,
  Image,
  Steps,
  Input,
  message,
  Typography,
  Modal,
  Row,
  AutoComplete,
  Select,
  Checkbox, InputNumber,
  Segmented,
  DatePicker,
} from "antd";
import FileIcon from "../../assets/img/icon/file.png";

import { BASE_URL_UPLOAD } from "../config/web-config";
import ImgCrop from "antd-img-crop";
import {
  actionAddProperty,
  actionGetCountry,
  actionGetCities,
  actionGetStates,
} from "../../store/actions/propertyAction";
import { actionGetAllUser } from "../../store/actions/userAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { PlusOutlined } from "@ant-design/icons";

const { Search, TextArea } = Input;
const { Text } = Typography;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const CreateProperty = (props) => {
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    actionAddProperty,
    actionGetCountry,
    actionGetStates,
    actionGetCities,
    countryLoader,
    countryData,
    addPropertiesLoader,
    stateLoader,
    stateData,
    cityLoader,
    cityData,
    actionGetAllUser,
    usersData,
    usersLoader
  } = props;

  const [disableState, setDisableState] = useState(true);
  const [disableCity, setDisableCity] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [fileVideoList, setFileVideoList] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [formValues, setFormValues] = useState({});
  const [imageFileList, setImageFileList] = useState([]);

  const [checkboxValues, setCheckboxValues] = useState({
    applinces: [],
    basement: [],
    floor_covering: [],
    rooms: [],
    indoor_features: [],
    building_amenities: [],
    architectural_style: [],
    exterior: [],
    outdoor_amenities: [],
    parking: [],
    roof: [],
    view: [],
    cooling_type: [],
    heating_type: [],
    parkingheating_fual: [],
  });


  const [autoCompleteResult, setAutoCompleteResult] = useState([]);



  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain, index) => ({
          id: index,
          value: `${value}${domain}`,
        }))
      );
    }
  };



  const handleParser = (value) => {

    // Remove non-numeric characters
    const numericValue = value & typeof value === 'number' ? value.toString().replace(/[^0-9]/g, '') : "";
    console.log(typeof value, numericValue, 'numericValue')
    return numericValue;
  };

  const websiteOptions = autoCompleteResult.map((website, index) => ({
    key: index,
    label: website,
    value: website,
  }));



  const onChange = (key, data) => {
    setCheckboxValues(prevState => ({
      ...prevState,
      [key]: data,
    }));
  };

  const handleFormChange = (changedValues, allValues) => {
    setFormValues(allValues);
  };

  // Get Estimated Value And Rental Values
  const handleInputChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  // Call The Country APi

  useEffect(() => {
    actionGetCountry();
    actionGetAllUser(0, 500, null, ['user', 'admin', 'agent'], true);
  }, []);

  // Get States APi Bases On  Selected Country
  const handleGetStates = (e, data) => {
    actionGetStates(data.isoCode);
    setDisableState(false);
  };

  // Make the API call to Get cities based on the selected States

  const handleGetCity = (e, data) => {
    // console.log(data);
    actionGetCities(data.countryCode, data.isoCode);
    setDisableCity(false);
  };

  const handleImageChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.filter((file) => file.type.includes("image"));

    setImageFileList(fileList);
    // Your other logic for handling image changes
  };


  const users = [];
  usersData && usersData.map((user, index) => {
    if (user.roles !== "user" ) {
      users.push({
        key: index,
        label: user.first_name + " " + user.last_name,
        value: user._id,
      });
    }
  })

  // Array Of Country
  const country = [];
  countryData &&
    countryData.length > 0 &&
    countryData.map((data, index) => {
      country.push({
        ...data,
        label: data.name,
        value: data.isoCode,
      });
    });

  // Array Of States
  const state = [];
  stateData &&
    stateData.length &&
    stateData.map((data, index) => {
      state.push({
        ...data,
        value: data.isoCode,
        label: data.name,
      });
    });

  // Array Of City
  const city = [];
  cityData &&
    cityData.length &&
    cityData.map((data, index) => {
      city.push({
        ...data,
        value: data.name,
        label: data.name,
      });
    });



  //  console.log(checkedValue, "checkedValue")

  // Steps Array in Steps
  const applinces = [
    { label: "Dishwasher", value: "Dishwasher" },
    { label: "Range/oven", value: "Range/oven" },
    { label: "Garbage disposal", value: "Garbage disposal" },
    { label: "Dryer", value: "Dryer" },
    { label: "Washer", value: "Washer" },
    { label: "Refrigerator", value: "Refrigerator" },
    { label: "Microwave", value: "Microwave" },
    { label: "Freezer", value: "Freezer" },
    { label: "Trash Compactor", value: "Trash Compactor" },
  ];
  const basement = [
    { label: "Unfinished", value: "Unfinished" },
    { label: "Carpet", value: "Carpet" },
    { label: "Partially Finished", value: "Partially Finished" },
    { label: "None", value: "None" },
  ];

  const floor_covering = [
    { label: "Carpet", value: "Carpet" },
    { label: "Hardwood", value: "Hardwood" },
    { label: "Slate", value: "Slate" },
    { label: "Tile", value: "Tile" },
    { label: "Concrete", value: "Concrete" },
    { label: "Laminate", value: "Laminate" },
    { label: "Softwood", value: "Softwood" },
    { label: "Other", value: "Other" },
  ];
  const rooms = [
    { label: "Breakfast nook", value: "Breakfast Nook" },
    { label: "Office", value: "Office" },
    { label: "Dining Room", value: "Dining Room" },
    { label: "Pantry", value: "Pantry" },
    { label: "Family Room", value: "Family Room" },
    { label: "Recreation Room", value: "Recreation Room" },
    { label: "Laundry Room", value: "Laundry Room" },
    { label: "Workshop", value: "Workshop" },
    { label: "Library", value: "Library" },
    { label: "Solarium/Atrium", value: "Solarium/Atrium" },
    { label: "Master Bath", value: "Master Bath" },
    { label: "Sun Room", value: "Sun Room" },
    { label: "Mud Room", value: "Mud Room" },
    { label: "Walk-In Closet", value: "Walk-In Closet" },
  ];

  const indoor_features = [
    { label: "Attic", value: "Attic" },
    { label: "Cable Ready", value: "Cable Ready" },
    { label: "Security System", value: "Security System" },
    { label: "Ceiling Fans", value: "Ceiling Fans" },
    { label: "Skylights", value: "Skylights" },
    { label: "Storm Windows", value: "Storm Windows" },
    { label: "Vaulted Ceiling", value: "Vaulted Ceiling" },
    { label: "Fireplace", value: "Fireplace" },
    { label: "Wet Bar", value: "Wet Bar" },
    { label: "Intercom System", value: "Intercom System" },
    { label: "Wired", value: "Wired" },
    { label: "Jetted Tub", value: "Jetted Tub" },
  ];
  const building_amenities = [
    { label: "Assisted Living", value: "Assisted Living" },
    { label: "Gated Entry", value: "Gated Entry" },
    { label: "Basketball Court", value: "Basketball Court" },
    { label: "Nearby Transport", value: "Nearby Transport" },
    { label: "Controlled Access", value: "Controlled Access" },
    { label: "Disabled Access", value: "Disabled Access" },
    { label: "Sports Court", value: "Sports Court" },
    { label: "Doorman", value: "Doorman" },
    { label: "Storage", value: "Storage" },
    { label: "Elevator", value: "Elevator" },
    { label: "Tennis Court", value: "Tennis Court" },
    { label: "Fitness Center", value: "Fitness Center" },
  ];
  const architectural_style = [
    { label: "Bungalow", value: "Bungalow" },
    { label: "Modern", value: "Modern" },
    { label: "Cape Cod", value: "Cape Cod" },
    { label: "Colonial", value: "Colonial" },
    { label: "Ranch/Rambler", value: "Ranch/Rambler" },
    { label: "Contemporary", value: "Contemporary" },
    { label: "Craftsman", value: "Craftsman" },
    { label: "Spanish", value: "Spanish" },
    { label: "French", value: "French" },
    { label: "Split Level", value: "Split Level" },
    { label: "Georgian", value: "Georgian" },
    { label: "Tudor", value: "Tudor" },
    { label: "Loft", value: "Loft" },
    { label: "Other", value: "Other" },
  ];
  const exterior = [
    { label: "Brick", value: "Brick" },
    { label: "Stucco", value: "Stucco" },
    { label: "Cement Concrete", value: "Cement/Concrete" },
    { label: "Vinyl", value: "Vinyl" },
    { label: "Composition", value: "Composition" },
    { label: "Wood", value: "Wood" },
    { label: "Metal", value: "Metal" },
    { label: "Wood Products", value: "Wood Products" },
    { label: "Shingle", value: "Shingle" },
    { label: "Stone", value: "Stone" },
    { label: "Other", value: "Other" },
  ];

  const outdoor_amenities = [
    { label: "Balcony/Patio", value: "Balcony/Patio" },
    { label: "Lawn", value: "Lawn" },
    { label: "Barbecue Area", value: "Barbecue Area" },
    { label: "Pond", value: "Pond" },
    { label: "Deck", value: "Deck" },
    { label: "Pool", value: "Pool" },
    { label: "Dock", value: "Dock" },
    { label: "Porch", value: "Porch" },
    { label: "Fenced Yard", value: "Fenced Yard" },
    { label: "RV Parking", value: "RV Parking" },
    { label: "Garden", value: "Garden" },
    { label: "Sauna", value: "Sauna" },
    { label: "Greenhouse", value: "Greenhouse" },
    { label: "Sprinkler System", value: "Sprinkler System" },
    { label: "Hot Tub/Spa", value: "Hot Tub/Spa" },
    { label: "Waterfront", value: "Waterfront" },
  ];

  const parking = [
    { label: "Carport", value: "Carport" },
    { label: "Off-Street", value: "Off-Street" },
    { label: "Garage Attached", value: "Garage Attached" },
    { label: "On-Street", value: "On-Street" },
    { label: "Garage Detached", value: "Garage Detached" },
    { label: "None", value: "None" },
  ];

  const roof = [
    { label: "Asphalt", value: "Asphalt" },
    { label: "Shake/Shingle", value: "Shake/Shingle" },
    { label: "Built-Up", value: "Built-Up" },
    { label: "Slate", value: "Slate" },
    { label: "Composition", value: "Composition" },
    { label: "Tile", value: "Tile" },
    { label: "Metal", value: "Metal" },
    { label: "Other", value: "Other" },
  ];

  const view = [
    { label: "City", value: "City" },
    { label: "Territorial", value: "Territorial" },
    { label: "Mountain", value: "Mountain" },
    { label: "Water", value: "Water" },
    { label: "Park", value: "Park" },
    { label: "None", value: "None" },
  ];

  const cooling_type = [
    { label: "Central", value: "Central" },
    { label: "Evaporative", value: "Evaporative" },
    { label: "Geothermal", value: "Geothermal" },
    { label: "Refrigeration", value: "Refrigeration" },
    { label: "Solar", value: "Solar" },
    { label: "Wall", value: "Wall" },
    { label: "Other", value: "Other" },
    { label: "None", value: "None" },
  ];
  const heating_type = [
    { label: "Baseboard", value: "Baseboard" },
    { label: "Forced Air", value: "Forced Air" },
    { label: "Geothermal", value: "Geothermal" },
    { label: "Heat Pump", value: "Heat Pump" },
    { label: "Radiant", value: "Radiant" },
    { label: "Stove", value: "Stove" },
    { label: "Wall", value: "Wall" },
    { label: "Other", value: "Other" },
  ];
  const parkingheating_fual = [
    { label: "Coal", value: "Coal" },
    { label: "Electric", value: "Electric" },
    { label: "Gas", value: "Gas" },
    { label: "Oil", value: "Oil" },
    { label: "Propane/Butane", value: "Propane/Butane" },
    { label: "Other", value: "Other" },
    { label: "Solar", value: "Solar" },
    { label: "Wood/Pellet", value: "Wood/Pellet" },
    { label: "None", value: "None" },
  ];
  // Steps Array in Steps

  useEffect(() => {
    console.log("called");
  }, [])

  const steps = [
    {
      title: "Room Details",
      content: (
        <>
          <Row className="custom-checkbox">
            <Col span={4}>
              <Form.Item
                label={<p style={{ fontWeight: 500 }}>applinces</p>}
                className="text-justify"
              >
                <Checkbox.Group options={applinces} value={checkboxValues.applinces} onChange={(data) => onChange("applinces", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={<p style={{ fontWeight: 500 }}>BASEMENT</p>}
                className="text-justify"
              >
                <Checkbox.Group options={basement} value={checkboxValues.basement}
                  onChange={(data) => onChange("basement", data)} />
              </Form.Item>
              <Form.Item
                label={<p style={{ fontWeight: 500 }}>FLOOR COVERING</p>}
                className="text-justify"
              >
                <Checkbox.Group options={floor_covering} value={checkboxValues.floor_covering}
                  onChange={(data) => onChange("floor_covering", data)} />

              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item

                label={<p style={{ fontWeight: 500 }}>ROOMS</p>}
                className="text-justify"
              >
                <div style={{ textAlign: "left", marginTop: 5, marginLeft: 5 }}>
                  <Text>Total No. rooms</Text>
                </div>
                <Checkbox.Group options={rooms} value={checkboxValues.rooms}
                  onChange={(data) => onChange("rooms", data)} />

              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="indoor_features"
                label={<p style={{ fontWeight: 500 }}>INDOOR FEATURES</p>}
                className="text-justify"
              >
                <Checkbox.Group options={indoor_features} value={checkboxValues.indoor_features}
                  onChange={(data) => onChange("indoor_features", data)} />

              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Building Details",
      content: (
        <>
          <Row className="custom-checkbox">
            <Col span={4}>
              <Form.Item
                // name="building_amenities"
                label={<p style={{ fontWeight: 500 }}>BUILDING AMENITIES</p>}
                className="text-justify"
              >
                <Checkbox.Group options={building_amenities} value={checkboxValues.building_amenities}
                  onChange={(data) => onChange("building_amenities", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="architectural_style"
                label={<p style={{ fontWeight: 500 }}>ARCHITECTURAL STYLE</p>}
                className="text-justify"
              >
                <Checkbox.Group options={architectural_style} value={checkboxValues.architectural_style} onChange={(data) => onChange("architectural_style", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="exterior"
                label={<p style={{ fontWeight: 500 }}>EXTERIOR</p>}
                className="text-justify"
              >
                <Checkbox.Group options={exterior} value={checkboxValues.exterior} onChange={(data) => onChange("exterior", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="outdoor_amenities"
                label={<p style={{ fontWeight: 500 }}>OUTDOOR AMENITIES</p>}
                className="text-justify"
              >
                <div style={{ textAlign: "left", marginTop: 5, marginLeft: 5 }}>
                  <Text>No. of stories</Text>
                </div>
                <Checkbox.Group options={outdoor_amenities} value={checkboxValues.outdoor_amenities} onChange={(data) => onChange("outdoor_amenities", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="parking"
                label={<p style={{ fontWeight: 500 }}>PARKING</p>}
                className="text-justify"
              >
                <div style={{ textAlign: "left", marginTop: 5, marginLeft: 5 }}>
                  <Text>Parking Spaces</Text>
                </div>
                <Checkbox.Group options={parking} value={checkboxValues.parking} onChange={(data) => onChange("parking", data)} />

              </Form.Item>
              <Form.Item
                // name="roof"
                label={<p style={{ fontWeight: 500 }}>ROOF</p>}
                className="text-justify"
              >
                <Checkbox.Group options={roof} value={checkboxValues.roof} onChange={(data) => onChange("roof", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="view"
                label={<p style={{ fontWeight: 500 }}>VIEW</p>}
                className="text-justify"
              >
                <Checkbox.Group options={view} value={checkboxValues.view} onChange={(data) => onChange("view", data)} />

              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Utility Details",
      content: (
        <>
          <Row className="custom-checkbox">
            <Col span={4}>
              <Form.Item
                // name="cooling_type"
                label={<p style={{ fontWeight: 500 }}>COOLING TYPE</p>}
                className="text-justify"
              >
                <Checkbox.Group options={cooling_type} value={checkboxValues.cooling_type} onChange={(data) => onChange("cooling_type", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="heating_type"
                label={<p style={{ fontWeight: 500 }}>HEATING TYPE</p>}
                className="text-justify"
              >
                <Checkbox.Group options={heating_type} value={checkboxValues.heating_type} onChange={(data) => onChange("heating_type", data)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                // name="parkingheating_fual"
                label={<p style={{ fontWeight: 500 }}>HEATING FUEL</p>}
                className="text-justify"
              >
                <Checkbox.Group options={parkingheating_fual} value={checkboxValues.parkingheating_fual} onChange={(data) => onChange("parkingheating_fual", data)} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  // Close The Modal Of preview Image
  const handleCancel = () => setPreviewOpen(false);

  // Preview Image OnClick

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };


  const handleChangeVideo = ({ fileList: newFileVideoList }) => {
    let videoFileList =
      newFileVideoList &&
      newFileVideoList.map((file) => {
        if (file) {
          // Set thumbnail image for video files

          file.thumbUrl =
            file.response &&
            file.response.file.path.substring(
              0,
              file.response.file.path.lastIndexOf(".")
            ) + "-thumb.png";
        }
        return file;
      });

    const videoSize =
      videoFileList &&
      videoFileList.some(
        (file) =>
          file.type.startsWith("video/") && file.size / 1024 / 1024 > 100
      );

    if (videoSize) {
      videoFileList = videoFileList.filter(
        (file) =>
          !file.type.startsWith("video/") || file.size / 1024 / 1024 <= 100
      );
      message.error("Maximum video size allowed is 100MB!", 2);
    }

    setFileVideoList(videoFileList);
  };

  // Upload text Or Button

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // Submit the Form And Call The Api


  const onFinish = (values) => {
    const imagesArr = [];
    const videoArr = [];

    // Image set to property image

    imageFileList &&
      imageFileList.length > 0 &&
      imageFileList.map((d) => {
        if (d.response) {
          return imagesArr.push(d.response.file.filename);
        } else if (d.is_cropper_image) {
          return imagesArr.push(d.is_cropper_image);
        } else return imagesArr.push(d.filename);
      });

    // video set to property image
    fileVideoList &&
      fileVideoList.length > 0 &&
      fileVideoList.map((d) => {
        if (d.response) {
          return videoArr.push(d.response.file.filename);
        } else return videoArr.push(d.filename);
      });





    const request = {
      ...values,
      applinces:
        checkboxValues && checkboxValues.applinces ? checkboxValues.applinces : [],
      basement:
        checkboxValues && checkboxValues.basement ? checkboxValues.basement : [],
      rooms: checkboxValues && checkboxValues.rooms ? checkboxValues.rooms : [],
      floor_covering:
        checkboxValues && checkboxValues.floor_covering
          ? checkboxValues.floor_covering
          : [],
      finished_square_feet: values.finished_square_feet ? values.finished_square_feet : "",
      indoor_features:
        checkboxValues && checkboxValues.indoor_features
          ? checkboxValues.indoor_features
          : [],
      building_amenities:
        checkboxValues && checkboxValues.building_amenities
          ? checkboxValues.building_amenities
          : [],
      architectural_style:
        checkboxValues && checkboxValues.architectural_style
          ? checkboxValues.architectural_style
          : [],
      exterior:
        checkboxValues && checkboxValues.exterior ? checkboxValues.exterior : [],
      outdoor_amenities:
        checkboxValues && checkboxValues.outdoor_amenities
          ? checkboxValues.outdoor_amenities
          : [],
      parking: checkboxValues && checkboxValues.parking ? checkboxValues.parking : [],
      roof: checkboxValues && checkboxValues.roof ? checkboxValues.roof : [],
      view: checkboxValues && checkboxValues.view ? checkboxValues.view : [],
      cooling_type:
        checkboxValues && checkboxValues.cooling_type
          ? checkboxValues.cooling_type
          : [],
      heating_type:
        checkboxValues && checkboxValues.heating_type
          ? checkboxValues.heating_type
          : [],
      parkingheating_fual:
        checkboxValues && checkboxValues.parkingheating_fual
          ? checkboxValues.parkingheating_fual
          : [],
      estimated_value: {
        low:
          inputValue && inputValue.estimated_low ? inputValue.estimated_low : 0,
        estimated:
          inputValue && inputValue.estimated_estimate
            ? inputValue.estimated_estimate
            : 0,
        high:
          inputValue && inputValue.estimated_high
            ? inputValue.estimated_high
            : 0,
      },
      rental_analysis: {
        low: inputValue && inputValue.rental_low ? inputValue.rental_low : 0,
        rent: inputValue && inputValue.rental_rent ? inputValue.rental_rent : 0,
        high: inputValue && inputValue.rental_high ? inputValue.rental_high : 0,
      },
      unit_no: values.unit_no ? values.unit_no : "",
      basement_square_feet: values.basement_square_feet
        ? values.basement_square_feet
        : "",
      garage_square_feet: values.garage_square_feet
        ? values.garage_square_feet
        : "",
      description: values.description ? values.description : "",
      related_website: values.related_website ? values.related_website : "",
      describe_the_neighbourhood: values.describe_the_neighbourhood
        ? values.describe_the_neighbourhood
        : "",
      parking_spaces: values.parking_spaces ? values.parking_spaces : "",
      estimated_taxes: values.estimated_taxes ? values.estimated_taxes : "",
      estimated_mortgage: values.estimated_mortgage
        ? values.estimated_mortgage
        : "",
      constructions: values.constructions ? values.constructions : "",
      fireplaces: values.fireplaces ? values.fireplaces : "",
      foundation: values.foundation ? values.foundation : "",
      other_source: values.other_source ? values.other_source : "",
      more_info_link: values.more_info_link ? values.more_info_link : "",
      property_images: imagesArr.concat(videoArr),
      asking_price: parseInt(values.asking_price),
      hoa_dues: parseInt(values.hoa_dues),
      year_built: values.year_built
        ? dayjs(values.year_built).format("YYYY")
        : "",
      structural_remodel_year: values.structural_remodel_year
        ? dayjs(values.structural_remodel_year).format("YYYY")
        : "",
      user: values.user ? values.user : "",
    };

    // console.log("checkboxValues.applinces:", checkedValue.applinces);
    console.log("Success:", request);
    actionAddProperty(request, Navigate);
  };

  // Error Show if Form values blank or error in!
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);

    const layout = document.querySelectorAll(".ant-layout-content");
    layout.forEach((element) => {
      element.scrollTo({
        top: 0,
      });
    });

  };

  /*   useEffect(() => {
      const layout = document.querySelectorAll(".ant-layout-content");
      layout.forEach((element) => {
        element.scrollTo({
          top: 0,
        });
      });
    }, []) */

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    textAlign: "center",
    color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    lineHeight: 5,
    borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="form-card-border"
            title={<h3>Create Property</h3>}
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  onValuesChange={handleFormChange}
                  name="validateOnly"
                  form={form}
                  autoComplete="off"
                  requiredMark={true}
                  // form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Select User"
                        colon={false}
                        name="user"
                        rules={[
                          {
                            required: false,
                            message: "Please Select User!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select User"
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          loading={usersLoader}
                          disabled={usersLoader}
                          filterOption={filterOption}
                          options={users}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Street address"
                        name="street_address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Street address*!",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Street address"
                          rows={1}
                          autoFocus="none"
                          type={"text"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Home type"
                        name="home_type"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Home type*!",
                          },
                        ]}
                      >
                        <Select
                          // defaultValue="Single Family"
                          showSearch
                          placeholder="Select a Home Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Single Family",
                              label: "Single Family",
                              selected: true,
                            },
                            { value: "Condo", label: "Condo" },
                            { value: "Townhomes", label: "Townhomes" },
                            { value: "Apartment", label: "Apartment" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Country"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Country*!",
                          },
                        ]}
                      >
                        <Select
                          loading={countryLoader}
                          onSelect={handleGetStates}
                          onChange={() => form.setFieldsValue({
                            state: null,
                            city: null,
                          })}
                          allowClear
                          showSearch
                          placeholder="Select Country"
                          optionFilterProp="children"
                          filterOption={(iv, op) =>
                            op.label
                              .toLocaleLowerCase()
                              .includes((iv || "").toLocaleLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={country}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="State"
                        disabled={disableState}
                        name="state"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your State*!",
                          },
                        ]}
                      >
                        <Select
                          loading={stateLoader}
                          onSelect={handleGetCity}
                          disabled={disableState ? true : false}
                          showSearch
                          placeholder="Select State"
                          optionFilterProp="children"
                          filterOption={(iv, op) =>
                            op.label
                              .toLocaleLowerCase()
                              .includes((iv || "").toLocaleLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={state}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="City"
                        name="city"
                        // normalize={(value) =>
                        //   value.replace(/[^a-zA-Z]/g, "").trim()
                        // }
                        rules={[
                          {
                            required: true,
                            message: "Please input your City*!",
                          },
                        ]}
                      >
                        <Select
                          loading={cityLoader}
                          disabled={disableCity ? true : false}
                          showSearch
                          placeholder="Select City"
                          optionFilterProp="children"
                          filterOption={(iv, op) =>
                            op.label
                              .toLocaleLowerCase()
                              .includes((iv || "").toLocaleLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={city}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Zip Code"
                        colon={false}
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Zip Code!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length === 5
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Zip Code cannot exceed 5 characters!"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Zip Code"
                          autoFocus="none"
                          type={"number"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Source"
                        colon={false}
                        name="source"
                        rules={[
                          {
                            required: true,
                            message: "Please select Source.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a Sorce"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "visit",
                              label: "visit",
                            },
                            {
                              value: "Zillow",
                              label: "Zillow",
                            },
                            {
                              value: "Realtor",
                              label: "Realtor",
                            },
                            {
                              value: "LoopNet",
                              label: "LoopNet",
                            },
                            {
                              value: "Other",
                              label: "Other",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Asking Price"
                        colon={false}
                        name="asking_price"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Asking Price*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Asking Price"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        //  normalize={(value) => value && value.trim}
                        label="Unit No"
                        colon={false}
                        name="unit_no"

                        rules={[
                          {
                            required: false,
                            message: "Please Enter Unit No!",
                          },

                        ]}
                      >
                        <Input
                          /* formatter={(value) => value && typeof value === 'number' ? String(value).replace(/[^0-9]/g, '') : ''}
                          parser={(value) => value ? String(value).replace(/[^0-9]/g, '') : ''} */
                          type={"number"}
                          style={{ width: '100%', border: '1px solid #d9d9d9', boxShadow: 'none' }}
                          min={0}
                          controls={false}
                          step={false}
                          changeOnBlur={false}
                          placeholder="Enter Unit No"
                        />


                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        label="Beds"
                        colon={false}
                        name="beds"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Beds*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Beds"
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Beds"
                        name="beds"
                        rules={[
                          {
                            required: true,
                            message: "Please input beds*!",
                          },
                        ]}
                      >
                        <Select
                          // defaultValue="Single Family"
                          showSearch
                          placeholder="Select a Beds"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            { value: "1", label: "1" },
                            { value: "2", label: "2" },
                            { value: "3", label: "3" },
                            { value: "4", label: "4" },
                            { value: "5+", label: "5+" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        label="Baths"
                        colon={false}
                        name="baths"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Baths*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Bath"
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Baths"
                        name="baths"
                        rules={[
                          {
                            required: true,
                            message: "Please input baths*!",
                          },
                        ]}
                      >
                        <Select
                          // defaultValue="Single Family"
                          showSearch
                          placeholder="Select a Bath"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            { value: "1", label: "1" },
                            { value: "1.5", label: "1.5" },
                            { value: "2", label: "2" },
                            { value: "2.5", label: "2.5" },
                            { value: "3+", label: "3+" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Finished square feet"
                        colon={false}
                        name="finished_square_feet"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Finished square feet*!",
                          },
                        ]}
                      >
                        <Input step={false}
                          type={"number"}
                          style={{ width: '100%', border: '1px solid #d9d9d9', boxShadow: "none" }}
                          placeholder="Enter Finished Square Feet"

                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Lot size"
                        colon={false}
                        name="lot_size"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Lot size*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Lot Size"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Year built"
                        colon={false}
                        name="year_built"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Year built*!",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={onChange}
                          picker="year"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Status"
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Status!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a Status"
                          // defaultValue={"For Sale"}

                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            { value: "For Sale", label: "For Sale" },
                            { value: "For Rent", label: "For Rent" },
                            { value: "Sold", label: "Sold" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Estimated Taxes"
                        colon={false}
                        name="estimated_taxes"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Year Estimated Taxes*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Estimated Tax"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Estimated Value"
                        colon={false}
                        // name="estimated_value"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Year Estimated Value*!",
                          },
                        ]}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <Input
                              name="estimated_low"
                              onChange={handleInputChange}
                              autoFocus="none"
                              type={"number"}
                              placeholder="Low"
                            />
                          </Col>
                          <Col span={8}>
                            <Input
                              name="estimated_estimate"
                              onChange={handleInputChange}
                              autoFocus="none"
                              type={"number"}
                              placeholder="Estimated"
                            />
                          </Col>
                          <Col span={8}>
                            <Input
                              name="estimated_high"
                              onChange={handleInputChange}
                              autoFocus="none"
                              type={"number"}
                              placeholder="High"
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Rental Analysis"
                        colon={false}
                        name="rental_ayalysis"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Year Estimated Analysis*!",
                          },
                        ]}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <Input
                              name="rental_low"
                              onChange={handleInputChange}
                              autoFocus="none"
                              type={"number"}
                              placeholder="Low"
                            />
                          </Col>
                          <Col span={8}>
                            <Input
                              autoFocus="none"
                              onChange={handleInputChange}
                              name="rental_rent"
                              type={"number"}
                              placeholder="Rent"
                            />
                          </Col>
                          <Col span={8}>
                            <Input
                              autoFocus="none"
                              onChange={handleInputChange}
                              name="rental_high"
                              type={"number"}
                              placeholder="High"
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Estimated Mortgage"
                        colon={false}
                        name="estimated_mortgage"
                        rules={[
                          {
                            required: false,
                            message: "Please Enter Estimated Mortgage!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Estimated Mortgage"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Structural remodel year"
                        colon={false}
                        name="structural_remodel_year"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Structural remodel year*!",
                          },
                        ]}
                      >
                        <DatePicker picker="year" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="HOA dues"
                        colon={false}
                        name="hoa_dues"
                        rules={[
                          {
                            required: false,
                            message: "Please enter HOA dues*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Hoa Fees"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Basement sq. ft."
                        colon={false}
                        name="basement_square_feet"
                        // normalize={(value) =>
                        //   value.replace(/[^a-zA-Z]/g, "").trim()
                        // }
                        rules={[
                          {
                            required: false,
                            message: "Please enter Basement sq. ft.*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Enter Basement sqft"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Garage sq. ft."
                        colon={false}
                        name="garage_square_feet"
                        // normalize={(value) =>
                        //   value.replace(/[^a-zA-Z]/g, "").trim()
                        // }
                        rules={[
                          {
                            required: false,
                            message: "Please enter Garage sqft*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Garage sqft"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Describe the Property"
                        colon={false}
                        name="description"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Describe the Property*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Describe the Property"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Other Source"
                        colon={false}
                        name="other_source"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Other Source!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Other Source"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Parking Space"
                        colon={false}
                        name="parking_spaces"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Parking Space!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Parking Space "
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Foundation"
                        colon={false}
                        name="foundation"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Foundation!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Foundation"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Fireplaces"
                        colon={false}
                        name="fireplaces"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Fireplaces!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Fireplaces"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Constructions"
                        colon={false}
                        name="constructions"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Constructions!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter Constructions"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="More Info Link"
                        colon={false}
                        name="more_info_link"
                        rules={[
                          {
                            required: false,
                            message: "Please enter More Info Link!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Enter More Info Link "
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Describe the neighbourhood"
                        colon={false}
                        name="describe_the_neighbourhood"
                        rules={[
                          {
                            required: false,
                            message:
                              "Please enter Describe the neighbourhood*!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          placeholder="Describe Neighbourhood"
                          autoFocus="none"
                          type={"text"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{ marginTop: "30px" }}
                        colon={false}
                        // name="Describe the neighbourhood"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: false,
                            message:
                              "Please enter Describe the neighbourhood*!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          value={"Additional Information "}
                          style={{
                            color: "#443527",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "21px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="related_website"
                        label="Related Website"
                        rules={[
                          {
                            required: false,
                            message: "Please input related_website!",
                          },
                        ]}
                      >
                        <AutoComplete
                          options={websiteOptions}
                          onChange={onWebsiteChange}
                          placeholder="Related Website"
                        >
                          <Input />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col span={20} style={{ margin: "auto" }}>
                      <Steps
                        current={current}
                        percent={60}
                        labelPlacement="vertical"
                        items={items}
                      />
                      <div style={contentStyle}>{steps[current].content}</div>
                      <div
                        style={{
                          marginTop: 24,
                        }}
                      >
                        {current > 0 && (
                          <Button
                            style={{
                              margin: "0 8px",
                            }}
                            onClick={() => prev()}
                          >
                            Previous
                          </Button>
                        )}
                        {current < steps.length - 1 && (
                          <Button type="primary" onClick={() => next()}>
                            Next
                          </Button>
                        )}
                      </div>
                    </Col>
                    <Col span={12} className="main-button-text">
                      <br />
                      <Form.Item
                        label="Property Images"
                        rules={[
                          {
                            required: true,
                            message: "Please Upload Property Image!",
                          },
                        ]}
                      >
                        <div>
                          <ImgCrop
                            rotationSlider
                            aspect={5 / 3}
                            fillColor="white"
                          >
                            <Upload
                              // multiple
                              name="file"
                              accept="image/*"
                              id="img-cropper"
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("scoutheimAdminToken"),
                              }}
                              action={BASE_URL_UPLOAD}
                              listType="picture-card"
                              // fileList={fileList}
                              fileList={imageFileList}
                              onPreview={handlePreview}
                              // onChange={handleChange}
                              onChange={handleImageChange}
                            // beforeUpload={beforeUpload}
                            >
                              {uploadButton}
                            </Upload>
                          </ImgCrop>
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{
                                width: "100%",
                              }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>
                      </Form.Item>
                    </Col>{" "}
                    <Col span={12} className="main-button-text">
                      <br />
                      <Form.Item
                        label="Property Video"
                        rules={[
                          {
                            required: true,
                            message: "Please Upload Property Video!",
                          },
                        ]}
                      >
                        <div>
                          <Upload
                            // multiple
                            name="file"
                            accept="video/*"
                            // id="img-cropper"
                            headers={{
                              Authorization:
                                "Bearer " +
                                localStorage.getItem("scoutheimAdminToken"),
                            }}
                            action={BASE_URL_UPLOAD}
                            listType="picture-card"
                            fileList={fileVideoList}
                            // onPreview={handlePreview}
                            onChange={handleChangeVideo}
                          // beforeUpload={beforeUpload}
                          >
                            {uploadButton}
                          </Upload>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: 30 }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={addPropertiesLoader}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryLoader: state.properties.countryLoader,
    countryData: state.properties.countryData,
    addPropertiesLoader: state.properties.addPropertiesLoader,
    stateLoader: state.properties.stateLoader,
    stateData: state.properties.stateData,
    cityLoader: state.properties.cityLoader,
    cityData: state.properties.cityData,
    usersData: state.user.usersData,
    usersLoader: state.user.usersLoader,

  };
};
export default connect(mapStateToProps, {
  actionAddProperty,
  actionGetCountry,
  actionGetCities,
  actionGetStates,
  actionGetAllUser,
})(CreateProperty);
