import axios from "axios";
import * as actionTypes from "../action";
import { message } from "antd";
import { BASE_URL } from "../../components/config/web-config";

export const actionGetAllUser =

  (
    skip,
    limit,
    search,
    role,
    getall,

  ) =>
    async (dispatch, getState) => {
      dispatch({ type: actionTypes.GET_USERS_LOADING, payload: true });
      try {
        var roles = "";
        typeof role === 'object' && role.map((r) => roles += "&role=" + r);
        let result = await axios.get(
          `${BASE_URL}/user?limit=${limit}&skip=${skip}${search ? "&search=" + search : ""
          }${role && typeof role === 'object' ? roles : (role ? "&role=" + role : "")
          }${getall ? "&getall=" + "Yes" : ""} `);
        // console.log("API Response:", result.data); 
        dispatch({
          type: actionTypes.GET_USERS_LOADING,
          payload: false,
        });
        if (parseInt(result.data.status) === 200) {
          dispatch({
            type: actionTypes.GET_USERS_DATA,
            payload: result.data.data,
          });
          // console.log("Total Records from API:", result.data.total_records.users);

          dispatch({
            type: actionTypes.TOTAL_USERS_COUNT,
            payload: result.data.total_records,
          });
        } else if (parseInt(result.data.status) === 404) {
          dispatch({
            type: actionTypes.TOTAL_USERS_COUNT,
            payload: 0,
          });
          dispatch({
            type: actionTypes.GET_USERS_DATA,
            payload: [],
          });
        } else message.error(result.data.message, 5);
      } catch (error) {
        message.error(error.message, 5);
        dispatch({
          type: actionTypes.GET_USERS_LOADING,
          payload: false,
        });
      }
    };

export const actionAddUser =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_USER_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/user`, userData);
      dispatch({ type: actionTypes.ADD_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_USER_DATA,
          payload: result.data.data,
        });
        message.success("User Added Successfully", 5);
        navigate && setTimeout(() => navigate("/users-agents"), 1000);
      } else if (result.data.status === 404) {
        dispatch({ type: actionTypes.ADD_USER_DATA, payload: [] });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_USER_LOADING, payload: false });
    }
  };

export const actionGetUserDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/user/${id}`);
    dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_USER_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: false });
  }
};

export const actionDeleteUser = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DELETE_USER_LOADING, payload: false });
  try {
    let result = await axios.delete(`${BASE_URL}/user/${id}`);
    dispatch({ type: actionTypes.DELETE_USER_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_USER_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.DELETE_USER_LOADING, payload: false });
  }
};

export const actionUpdateUser =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/user/${id}`, {
        ...userData,
      });
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success("User Updated Successfully", 5);
        navigate && setTimeout(() => navigate("/users-agents"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
    }
  };
export const actionVerifyAgent =
  (id, type) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.VERIFY_AGENT_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/user/verify-agent/${id}`, { type });
      dispatch({ type: actionTypes.VERIFY_AGENT_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {

        type == 'Approve' && dispatch({ type: actionTypes.VERIFY_AGENT_ID, payload: id });
        message.success(result.data.message, 5);
        //navigate && setTimeout(() => navigate("/users-agents"), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.VERIFY_AGENT_LOADING, payload: false });
    }
  };
