import { Card, Image, Button, Checkbox, Form, Input } from "antd";
import React, { useEffect } from "react";
import logo from "../../assets/img/main-wrapper/logo.png";
import { actionLogin } from "../../store/actions/authAction";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Login = (props) => {
  const Navigate = useNavigate();
  const { actionLogin, LoginLoader } = props;
  const [form] = Form.useForm();
  const scoutheimEmail = localStorage.getItem("scoutheimEmail");
  const scoutheimPassword = localStorage.getItem("scoutheimPassword");

  useEffect(() => {
    if (localStorage.scoutheimAdminToken) {
      Navigate(`/`);
    }
    scoutheimEmail &&
      scoutheimPassword &&
      form.setFieldsValue({
        email: scoutheimEmail,
        password: scoutheimPassword,
      });
  }, []);

  const onFinish = (values) => {
    actionLogin(values, Navigate);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-wrapper">
      <Card>
        <div className="login-logo-wrapper">
          <Image src={logo} preview={false} />
        </div>
        <p className="title">Sign in</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          {/* <Form.Item
            name="fcm_id"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input placeholder="fcm id" />
          </Form.Item> */}

          <div className="bottom-button">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={LoginLoader}>
                Submit
              </Button>
            </Form.Item>
          </div>
          <Form.Item style={{ textAlign: "center" }}>
            <Link
              style={{ color: "#FF9A3E", fontWeight: 600 }}
              className="link-color"
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    LoginLoader: state.auth.LoginLoader,
  };
};

export default connect(mapStateToProps, { actionLogin })(Login);
