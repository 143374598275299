import React, { useEffect, useState } from "react";
import {
    PullRequestOutlined,
    EllipsisOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined
} from "@ant-design/icons";
import {
    actionDeleteUser,
    actionVerifyAgent,
    actionGetAllUser,
} from "../../store/actions/userAction";
import {
    Col,
    Row,
    Image,
    Space,
    Button,
    Checkbox,
    Form,
    Input,
    Select,
    Table,
    Tag,
    Dropdown,
    Popconfirm,
    Spin,
    Modal,
} from "antd";
import verifiedIcon from "../../assets/img/icon/verify.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removeUnderScore } from "../../common/function";
import { actionApproveBusiness, actionDeleteBusinessUser, actionGetAllBusinessUsers, actionRejectBusiness, } from "../../store/actions/businessAction";

const BusinessRequest = (props) => {
    const {
        actionGetAllBusinessUsers,
        actionDeleteBusinessUser, actionRejectBusiness,
        businessUsersData,
        businessUsersCount, actionApproveBusiness,
        businessUsersLoader,
        deleteBusinessUserLoader,
        approveBusinessLoading, rejectBusinessLoading,
    } = props;
    const [form] = Form.useForm();
    const [search, setSearch] = useState();
    const [role, setRole] = useState();
    const [userId, setUserId] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (id) => {
        setUserId(id);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        // actionRejectBusiness(userId)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setUserId("");
    };

    const onFinishReject = (val) => {
        actionRejectBusiness(userId, val, form, setIsModalOpen);

    }
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };



    useEffect(() => {
        actionGetAllBusinessUsers(0, 10);
    }, []);

    const confirm = (id) => {
        actionDeleteBusinessUser(id);
    };

    const onHandleVerification = (id, type) => {
        actionApproveBusiness(id);
    };

    const handleWebsiteClick = (modifiedUrl) => {
        console.log({ modifiedUrl });
        window.open(modifiedUrl, '_blank');
    };

    
    const data = [];
    businessUsersData &&
        businessUsersData.length > 0 &&
        businessUsersData.map((d, index) => {
            let icon = d.roles === "user" || d.roles === "admin" || d.roles === "agent" ? <img src={verifiedIcon} className="" width={18} style={{ marginLeft: '8px' }} /> : '';
            data.push({
                key: index,
                name: (d.first_name ? d.first_name : "") + (d.last_name ? " " + d.last_name : ""),
                email: d.email || "-",
                mobile: d.mobile || "-",
                source: d.source || "-",
                id: d._id,
                rejected_note: d.note || "-",
                business_name: d.business_name || "-",
                business_website: <div className="website-link"
                    onClick={() => handleWebsiteClick(d.business_website)}>{d.business_website}</div> || "-",
                business_address: d.business_address || "-",
            });

        });

    const columns = [

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 150,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 150,
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            width: 150,
        },

        {
            title: "Business Name",
            dataIndex: "business_name",
            key: "business_name",
            width: 150,
        },
        {
            title: "Business Website",
            dataIndex: "business_website",
            key: "business_website",
            render: (text) => text || "-",
            ellipsis: true,
            width: 150,
        },
        {
            title: "Business Address",
            dataIndex: "business_address",
            key: "business_address",
            width: 150,
        },
        {
            title: "Reason For Rejecting Request",
            dataIndex: "rejected_note",
            ellipsis: true,
            key: "rejected_note",
            width: 150,
        },

        {
            title: "Source",
            dataIndex: "source",
            key: "source",
            width: 150,
        },
        {
            title: "Action",
            fixed: "right",
            align: "center",
            width: "80px",
            key: "action",
            render: (_, item) => (
                <>
                    <Dropdown
                        placement="bottom"
                        menu={{
                            items: [
                                {
                                    label: (
                                        <span>
                                            <Link
                                                to={`/business-request/${item.id}`}
                                                style={{ color: "black" }}
                                            >
                                                <EyeOutlined
                                                    style={{
                                                        color: "#FF9A3E",
                                                        fontSize: "18px",
                                                        marginRight: 10,
                                                    }}
                                                />
                                                View
                                            </Link>
                                        </span>
                                    ),
                                },
                                {
                                    label: (
                                        <span>
                                            <Link
                                                to={`/update-business-request/${item.id}`}
                                                style={{ color: "black" }}
                                            >
                                                <EditOutlined
                                                    style={{
                                                        color: "#FF9A3E",
                                                        fontSize: "18px",
                                                        marginRight: 10,
                                                    }}
                                                />
                                                Edit
                                            </Link>
                                        </span>
                                    ),
                                }, {
                                    label: (
                                        <Popconfirm
                                            okButtonProps={{
                                                loading: deleteBusinessUserLoader,
                                            }}

                                            placement="topLeft"
                                            title="Are you sure to Delete this Business User?"
                                            onConfirm={() => confirm(item.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <span>
                                                <DeleteOutlined
                                                    style={{
                                                        color: "#FF9A3E",
                                                        fontSize: "18px",
                                                        marginRight: 10,
                                                    }}
                                                />
                                                Delete
                                            </span>
                                        </Popconfirm>
                                    ),
                                },

                                {
                                    label: (
                                        <Popconfirm
                                            okButtonProps={{
                                                loading: approveBusinessLoading,
                                            }}

                                            placement="topLeft"
                                            title="Are you sure to approve this Request?"
                                            onConfirm={() => onHandleVerification(item.id, 'Approve')}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <span>
                                                <CheckOutlined
                                                    style={{
                                                        color: "#FF9A3E",
                                                        fontSize: "18px",
                                                        marginRight: 10,
                                                    }}
                                                />
                                                Approve Request
                                            </span>
                                        </Popconfirm>
                                    ),
                                },

                                {
                                    label: (
                                        <>

                                            <span onClick={() => {
                                                showModal(item.id)

                                            }}>
                                                <CloseOutlined
                                                    style={{
                                                        color: "#FF9A3E",
                                                        fontSize: "18px",
                                                        marginRight: 10,
                                                    }}
                                                />
                                                Reject Request
                                            </span>

                                        </>
                                    ),
                                },

                            ],
                        }}
                    >
                        <a>
                            <EllipsisOutlined style={{ fontSize: "20px" }} />
                        </a>

                    </Dropdown>
                </>
            ),
        },
    ];

    const onClickPaginationNumber = (e) => {
        actionGetAllBusinessUsers(e.current - 1, e.pageSize, search, role);
    };
    const onFinishSearch = (value) => {
        setSearch(value.search ? value.search : "");
        actionGetAllBusinessUsers(
            0,
            10,
            value.search ? value.search : "",
        );
    };


    return (
        <div>

            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row className="main-card-wrapper" gutter={16}>
                    <Col span={24}>
                        <div className="card-blue">
                            <Row>
                                <Col span={20} className="text-card">
                                    <p className="font-title">Business Request</p>
                                    <p className="font-text">
                                        {businessUsersCount ? businessUsersCount : 0}
                                    </p>
                                </Col>
                                <Col span={4} className="image-card-blue">
                                    {/* <Image src={userIcon} preview={{ mask: "" }} /> */}
                                    <PullRequestOutlined style={{ color: "white", fontSize: 20 }} />
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Row>
                <Row
                    className="main-card-wrapper"
                    gutter={16}
                    justify="space-between"
                    align="middle"
                >
                    <Col span={12} style={{ fontWeight: "bold", fontSize: "15px" }}>
                        Business Request
                    </Col>
                    <Col span={12} align="right">
                        {" "}
                        <Form
                            name="basic"
                            layout="inline"
                            onFinish={onFinishSearch}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            style={{ justifyContent: "end" }}
                        >
                            <Form.Item name="search">
                                <Input placeholder="Search Here..." />
                            </Form.Item>

                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }}>
                        <Table
                            columns={columns}
                            loading={businessUsersLoader || approveBusinessLoading || deleteBusinessUserLoader}
                            sticky={true}
                            pagination={{
                                total: businessUsersCount.records, // add total Property Count Here
                                hideOnSinglePage: true,
                            }}
                            size="small"
                            dataSource={data}
                            onChange={(e) => onClickPaginationNumber(e)}
                            scroll={{
                                x: 1500,
                                y: window.innerWidth < 1500 ? "40vh" : "50vh",
                            }}
                        />
                    </Col>
                </Row>
            </Space>

            <Modal footer={false} title="Reason For Reject Business" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form layout="vertical" style={{ padding: "10px 0px" }}
                    name="basic"
                    form={form}
                    onFinish={onFinishReject}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Reason For Rejecting Request"
                        name="note"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Reason!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Reason For Rejecting Request" />
                    </Form.Item>

                    <Form.Item
                        style={{ paddingTop: 20 }}
                    >
                        <Button htmlType="Submit" loading={rejectBusinessLoading}>Submit</Button>
                    </Form.Item>

                </Form>
            </Modal>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        businessUsersLoader: state.business.businessUsersLoader,
        businessUsersData: state.business.businessUsersData,
        deleteBusinessUserLoader: state.business.deleteBusinessUserLoader,
        businessUsersCount: state.business.businessUsersCount,
        approveBusinessLoading: state.business.approveBusinessLoading,
        rejectBusinessLoading: state.business.rejectBusinessLoading,
    };
};

export default connect(mapStateToProps, { actionApproveBusiness, actionRejectBusiness, actionGetAllBusinessUsers, actionDeleteBusinessUser, actionVerifyAgent })(BusinessRequest);