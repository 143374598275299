import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../assets/img/icon/file.png";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { actionGetAllBusiness } from "../../store/actions/businessAction";
import { actionAddDeal } from "../../store/actions/dealsAction";
const { Search, TextArea } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateDeal = (props) => {
  const {
    actionGetAllBusiness,
    userProfileData,
    actionAddDeal,
    addDealLoader,
    businessLoader,
    businessData,
  } = props;
  const [loading, setLoading] = useState(false);
  const [userPicUrl, setUserPicUrl] = useState("");
  const [disableDate, setDisableDate] = useState(true);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [err, setErr] = useState("");
  const inputRef = useRef(null);

  const Navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    actionGetAllBusiness(0, 20);
  }, []);

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setUserPicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    const request = {
      ...values,
      image: userPicUrl ? userPicUrl.split("/").reverse()[0] :"",
      from_date: dayjs(values.from_date).format("YYYY-MM-DD"),
      to_date: dayjs(values.to_date).format("YYYY-MM-DD"),
    };
    console.log({ request, values });
    actionAddDeal(request, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disableEndDate = (current) => {
    const startDate = form && form.getFieldValue("from_date");
    return (
      (current && startDate && current < dayjs(startDate).startOf("day")) ||
      current.isSame(dayjs(startDate), "day")
    );
  };

  const onNameChange = (event) => {
    setName(event.toString());
  };
  const addItem = (e) => {
    setErr("");

    if (items.length >= 3) {
      setErr("You can add only three Zip codes!");
      setTimeout(() => {
        setErr("");
      }, 5000); 
      return;
    }

    if (!name || name.toString().length !== 5) {
      setErr("Enter a valid input of length 5 characters!");
      return false;
    }

    if (items.includes(name)) {
      setErr("Zip code already exists!");
      return;
    }

    e.preventDefault();
    setItems([...items, name]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const businesses = [];
  businessData &&
    businessData.map((business, index) => {
      if (business.is_active == true){
        businesses.push({
          key: index,
          label: business.business_name,
          value: business._id,
        });
      }
    });

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const disabledPastDateInStartDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="form-card-border"
            title={<h3>Create Deal</h3>}
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Select Business"
                        colon={false}
                        name="business"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Business!",
                          },
                        ]}
                      >
                        <Select
                          loading={businessLoader}
                          disabled={businessLoader}
                          placeholder="Select Business"
                          allowClear
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          filterOption={filterOption}
                          options={businesses}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Property Offer"
                        name="property_offer"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Property Offer!",
                          },
                        ]}
                      >
                        <Input
                          multiple
                          autoFocus="none"
                          type={"text"}
                          placeholder="Property Offer"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Zip Code"
                        colon={false}
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Zip Code!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          style={{
                            width: "100%",
                          }}
                          showSearch={false}
                          // onSelect={(e) => console.log(e)}
                          placeholder="Enter Zip code"
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                              <Space
                                style={{
                                  padding: "0 8px 4px",
                                }}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  type="number"
                                  changeOnWheel
                                  maxLength={5}
                                  minLength={5}
                                  placeholder="Please enter item"
                                  ref={inputRef}
                                  value={name}
                                  onChange={onNameChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={addItem}
                                >
                                  Add item
                                </Button>
                              </Space>
                              {err && (
                                <p style={{ margin: "5px 10px", color: "red" }}>
                                  {err}
                                </p>
                              )}
                            </>
                          )}
                          options={items.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Code"
                        colon={false}
                        name="code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Code!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              // Regular expression to match only letters and numbers
                              const regex = /^[a-zA-Z0-9]*$/;
                              if (value && !regex.test(value)) {
                                return Promise.reject(
                                  new Error(
                                    "Code must contain only letters and numbers!"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && value.length > 10) {
                                return Promise.reject(
                                  new Error(
                                    "Code must be shorter than or equal to 10 characters!"
                                  )
                                );
                              }
                              if (value && value.length < 3) {
                                return Promise.reject(
                                  new Error(
                                    "Code must be at least 3 characters long!"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Code"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Start date"
                        colon={false}
                        name="from_date"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start date!",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={() => setDisableDate(false)}
                          disabledDate={disabledPastDateInStartDate}
                          allowClear
                          onClear={() => setDisableDate(true)}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="End date"
                        colon={false}
                        name="to_date"
                        rules={[
                          {
                            required: true,
                            message: "Please Select End date!",
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={disableDate}
                          disabledDate={disableEndDate}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Terms Condition"
                        name="terms_condition"
                        rules={[
                          {
                            required: true,
                            message: "Please input terms condition!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Terms and Conditions"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Address"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please input Address!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          autoFocus="none"
                          type={"text"}
                          placeholder="Address"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      {/* <br /> */}
                      <Form.Item
                        normalize={(value) => value.trim()}
                        name="image"
                        label="Image"
                        rules={[
                          {
                            required: false,
                            message: "Please upload an image!",
                          },
                        ]}
                      >
                        <div>
                          <>
                            <ImgCrop
                              rotationSlider
                              aspect={4 / 3}
                              fillColor="white"
                            >
                              <Upload
                                accept=".png,.jpg,.jpeg"
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={BASE_URL_UPLOAD}
                                headers={{
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem("scoutheimAdminToken"),
                                }}
                                onChange={handleChangeUserPic}
                              >
                                {userPicUrl ? (
                                  <img
                                    src={userPicUrl}
                                    alt="avatar"
                                    style={{
                                      width: "85%",
                                      height: "85%",
                                    }}
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </Upload>
                            </ImgCrop>
                          </>
                        </div>
                        {/* {userPicUrl && (
                          <Image
                            src={userPicUrl}
                            alt="file"
                            preview={false}
                            width={200}
                            style={{ marginTop: "10px" }}
                          />
                        )} */}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addDealLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    businessLoader: state.business.businessLoader,
    businessData: state.business.businessData,
    addDealLoader: state.dealz.addDealLoader,
    userProfileData: state.auth.userProfileData,
  };
};

export default connect(mapStateToProps, {
  actionGetAllBusiness,
  actionAddDeal,
})(CreateDeal);
